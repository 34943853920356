import React from 'react';
import { Input, InputContainer} from '../styles'

const ChatInput = ({ onChangeInput, placeholder, search=false, styles, styleContainer,value,onEnterPress }) => {

    return (
        <InputContainer  style={styleContainer}>
            <div className="gx-search-bar gx-chat-search-bar gx-lt-icon-search-bar-lg">
                <div className="gx-form-group">
                    <Input onKeyDown={onEnterPress} style={styles} value={value} className="ant-input" type={search ? "search": ""} placeholder={placeholder}  onChange={onChangeInput} />
                    {search ? <span className="gx-search-icon gx-pointer">
                        <i className="icon icon-search">
                        </i>
                    </span> : null}
                </div>
            </div>
        </InputContainer>
    );
};

export default ChatInput;