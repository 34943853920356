import {
  GET_CUSTOM_SCRIPTS,
  GET_CUSTOM_SCRIPTS_SUCCESS,
} from '../../constants/ActionTypes';

export const getCustomScripts = () => ({
  type: GET_CUSTOM_SCRIPTS,
});

export const getCustomScriptsSuccess = scripts => ({
  type: GET_CUSTOM_SCRIPTS_SUCCESS,
  payload: scripts,
});
