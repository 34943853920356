/* eslint-disable no-console */
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

import {
  fetchCommentSuccess,
  updateCommentSuccess,
  removeCommentSuccess,
  setCommentLikeSuccess,
  setCommentSuccess,
  setCommentIdReply,
  setCommentSuccessApi,
  setStyleEditComment,
} from '../actions';
import {
  GET_COMMENTS,
  UPDATE_COMMENT,
  REMOVE_COMMENT,
  SET_COMMENT,
  SET_COMMENT_LIKE,
} from '../../constants/ActionTypes';

import api from '../../services/api';

const getCommentRequest = async payload => {
  try {
    const { courseSlug, moduleSlug, lessonSlug } = payload;
    const response = await api.get(
      `course/${courseSlug}/${moduleSlug}/${lessonSlug}/comment`
    );

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const setCommentRequest = async payload => {
  try {
    const { courseSlug, moduleSlug, lessonSlug, comment, parent } = payload;
    let url = '';
    if (parent) {
      url = `course/${courseSlug}/${moduleSlug}/${lessonSlug}/comment/${parent}`;
    } else {
      url = `course/${courseSlug}/${moduleSlug}/${lessonSlug}/comment`;
    }

    const response = await api.post(url, { comment: comment.comment });
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const updateCommentRequest = async payload => {
  try {
    const {
      courseSlug,
      moduleSlug,
      lessonSlug,
      comment,
      userId,
      id,
      commentChildren,
    } = payload;
    const response = await api.put(
      `course/${courseSlug}/${moduleSlug}/${lessonSlug}/comment/${id}`,
      {
        comment,
        user_id: userId || commentChildren.commenter.id,
      }
    );
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const removeCommentRequest = async payload => {
  try {
    const { courseSlug, moduleSlug, lessonSlug, comment } = payload;
    const response = await api.delete(
      `course/${courseSlug}/${moduleSlug}/${lessonSlug}/comment/${comment.id}`
    );

    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const setCommentLikeRequest = async payload => {
  try {
    const { courseSlug, moduleSlug, lessonSlug, id, action } = payload;
    let url = null;
    if (action === 'like') {
      url = `course/${courseSlug}/${moduleSlug}/${lessonSlug}/like/comment/${id}`;
    }
    if (action === 'dislike') {
      url = `course/${courseSlug}/${moduleSlug}/${lessonSlug}/dislike/comment/${id}`;
    }

    const response = await api.post(url);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getComments({ payload }) {
  const comments = yield call(getCommentRequest, payload);
  if (comments.data)
    yield put(fetchCommentSuccess(comments.data, comments.totalComments));
}

function* setComment({ payload }) {
  const { comment, parent, totalComments } = payload;
  const { comments } = yield select();

  comments.totalComments = totalComments;

  if (parent) {
    const commentParent = comments.commentsList.filter(
      item => item.id === parent
    );
    // change state
    commentParent[0].children.push(comment);
    yield put(setCommentIdReply(null));

    // replace state with api return
    const newChildrenComment = yield call(setCommentRequest, payload);
    commentParent[0].children.pop();
    commentParent[0].children.push(newChildrenComment);
    yield put(setCommentSuccessApi(comments.commentsList));
  } else {
    comments.commentsList.unshift(comment);
    yield put(setCommentSuccess(comments.commentsList));

    const newComment = yield call(setCommentRequest, payload);
    comments.commentsList[0] = newComment;
    yield put(setCommentSuccessApi(comments.commentsList));
  }
}

function* updateComment({ payload }) {
  const { id, comment, commentChildren } = payload;
  const { comments } = yield select();

  if (commentChildren.parent_id) {
    commentChildren.comment = comment;
  } else {
    const commentChanged = comments.commentsList.filter(item => item.id === id);
    commentChanged[0].comment = comment;
  }
  yield put(setStyleEditComment(false));
  yield put(updateCommentSuccess(comments.commentsList));
  yield call(updateCommentRequest, payload);
}

function* removeComment({ payload }) {
  const { comment, totalComments } = payload;
  const { comments } = yield select();

  comments.totalComments = totalComments;

  if (comment.parent_id) {
    const commentParent = comments.commentsList.filter(
      item => item.id === Number(comment.parent_id)
    );
    const commentChildren = commentParent[0].children.filter(
      item => item.id !== comment.id
    );

    commentParent[0].children = commentChildren;
    yield put(removeCommentSuccess(comments.commentsList));
  } else {
    const commentsList = comments.commentsList.filter(
      item => item.id !== comment.id
    );
    yield put(removeCommentSuccess(commentsList));
  }
  yield call(removeCommentRequest, payload);
}

function* setCommentLike({ payload }) {
  yield call(setCommentLikeRequest, payload);
  yield put(setCommentLikeSuccess());
}

export function* fetchLessonComment() {
  yield takeEvery(GET_COMMENTS, getComments);
}

export function* setLessonComment() {
  yield takeEvery(SET_COMMENT, setComment);
}

export function* setLessonLikeComment() {
  yield takeEvery(SET_COMMENT_LIKE, setCommentLike);
}

export function* setUpdateLessonComment() {
  yield takeEvery(UPDATE_COMMENT, updateComment);
}

export function* removeLessonComment() {
  yield takeEvery(REMOVE_COMMENT, removeComment);
}

export default function* rootSaga() {
  yield all([
    fork(fetchLessonComment),
    fork(setLessonComment),
    fork(setLessonLikeComment),
    fork(setUpdateLessonComment),
    fork(removeLessonComment),
  ]);
}
