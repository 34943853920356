import styled from 'styled-components';
import { Row } from 'antd';

export const Container = styled.div`
  .ant-btn.ant-btn-circle.ant-btn-sm {
    border-radius: 50% !important;
  }
`;

export const ContainerPurchaseValues = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentRow = styled(Row)`
  display: flex;
  align-items: center;
`;
