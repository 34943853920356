import {
  FETCH_NOTES_SUCCESS,
  GET_NOTES,
  REMOVE_NOTE,
  SET_NOTE,
  SET_NOTE_SUCCESS,
  UPDATE_NOTE,
} from '../../constants/ActionTypes';

export const fetchNotes = lessonSlug => ({
  type: GET_NOTES,
  payload: {
    lessonSlug,
  },
});

export const updateNote = (lessonSlug, description, id, idx, time) => ({
  type: UPDATE_NOTE,
  payload: { lessonSlug, description, id, idx, time },
});

export const setNote = (lessonSlug, theSecond, Description) => ({
  type: SET_NOTE,
  payload: { lessonSlug, theSecond, Description },
});

export const removeNote = (lessonSlug, id, idx) => ({
  type: REMOVE_NOTE,
  payload: { lessonSlug, id, idx },
});

export const fetchNoteSuccess = notes => ({
  type: FETCH_NOTES_SUCCESS,
  payload: notes,
});

export const setNoteSuccess = note => ({
  type: SET_NOTE_SUCCESS,
  payload: note,
});
