import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  fetchMainMenuSuccess,
  fetchProfileMenuSuccess,
  fetchFooterMenuSuccess,
  fetchMenuShortcutsSuccess,
} from '../actions/Menu';
import {
  GET_MAIN_MENU,
  GET_PROFILE_MENU,
  GET_FOOTER_MENU,
  GET_MENU_SHORTCUTS,
} from '../../constants/ActionTypes';
import api from '../../services/api';

import { getToken } from '../../services/auth';

const getMainMenuRequest = async () => {
  try {
    const response = await api.get('menu/menu');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

const getProfileMenuRequest = async () => {
  try {
    api.interceptors.request.use(async config => {
      const token = getToken();
      if (token) config.headers.Authorization = `Bearer ${token}`;

      return config;
    });

    const response = await api.get('menu/profile');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

const getFooterMenuRequest = async () => {
  try {
    const response = await api.get('menu/footer');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

const getMenuShortcutsRequest = async () => {
  try {
    const response = await api.get('menu/topbar');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

function* getMainMenu() {
  const mainMenu = yield call(getMainMenuRequest);
  if (mainMenu) yield put(fetchMainMenuSuccess(mainMenu));
}

function* getProfileMenu() {
  const profileMenu = yield call(getProfileMenuRequest);
  if (profileMenu) yield put(fetchProfileMenuSuccess(profileMenu));
}

function* getFooterMenu() {
  const footerMenu = yield call(getFooterMenuRequest);
  if (footerMenu) yield put(fetchFooterMenuSuccess(footerMenu));
}

function* getMenuShortcuts() {
  const getMenuShortcuts = yield call(getMenuShortcutsRequest);
  if (getMenuShortcuts) yield put(fetchMenuShortcutsSuccess(getMenuShortcuts));
}

export function* fetchMainMenu() {
  yield takeEvery(GET_MAIN_MENU, getMainMenu);
}

export function* fetchProfileMenu() {
  yield takeEvery(GET_PROFILE_MENU, getProfileMenu);
}

export function* fetchFooterMenu() {
  yield takeEvery(GET_FOOTER_MENU, getFooterMenu);
}

export function* fetchMenuShortcuts() {
  yield takeEvery(GET_MENU_SHORTCUTS, getMenuShortcuts);
}

export default function* rootSaga() {
  yield all([
    fork(fetchMainMenu),
    fork(fetchProfileMenu),
    fork(fetchFooterMenu),
    fork(fetchMenuShortcuts),
  ]);
}
