import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Icon, Input, notification } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from 'appRedux/actions/Auth';
import CircularProgress from 'components/CircularProgress';
import logo from '../assets/images/logo_horizontal.png';

const FormItem = Form.Item;

const SignIn = ({ form, showAuthLoader, userSignIn, userFacebookSignIn, userGoogleSignIn, userGithubSignIn, userTwitterSignIn, authUser, showMessage, loader, alertMessage, hideMessage, history }) => {
  const openNotification = () => {
    const alertMessageStr = String(alertMessage);

    const args = {
      message: 'SignUp',
      description: alertMessageStr.charAt(0).toUpperCase() + alertMessageStr.slice(1),
      duration: 5,
    };
    notification.open(args);
  };

  useEffect(() => {
    if (showMessage) {
      openNotification();
      hideMessage();
    }
    // eslint-disable-next-line
  }, [showMessage, hideMessage]);

  useEffect(() => {
    if (authUser !== null) {
      history.push('/');
    }
  }, [authUser, history])

  const handleSubmit = e => {
    e.preventDefault();
    const { validateFields } = form;

    validateFields(async (err, values) => {
      if (!err) {
        showAuthLoader();
        userSignIn(values);
      }
    });
  };

  const { getFieldDecorator } = form;

  return (
    <div className="gx-login-container">
      <div
        className="gx-login-content"
        style={{ padding: '10px 35px 20px', position: 'absolute' }}
      >
        <div
          className="gx-login-header gx-text-center"
          style={{ marginBottom: 0 }}
        >
          <img src={logo} alt="" style={{ padding: '30px 0' }} />
        </div>
        <Form
          onSubmit={handleSubmit}
          className="gx-login-form gx-form-row0"
        >
          <FormItem>
            {getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: 'This is not a valid email.',
                },
              ],
            })(
              <Input
                style={{ height: '50px' }}
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                size="large"
                placeholder="Email"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'This is not a valid password.' },
              ],
            })(
              <Input
                style={{ height: '50px' }}
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                size="large"
                placeholder="Password"
              />
            )}
          </FormItem>
          <FormItem style={{ marginBottom: 5 }}>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
            <Link
              className="gx-login-form-forgot"
              to="/forgot"
            >
              Forgot password
            </Link>
          </FormItem>

          <FormItem className="gx-text-center">
            <Button type="primary" htmlType="submit" block size="large">
              Log in
            </Button>

            <Link style={{ color: '#545454' }} to="/signup">
              <Button type="primary" htmlType="button" block size="large">
                Sign Up with E-mail
            </Button>
            </Link>
          </FormItem>
          <div className="gx-flex-row gx-justify-content-between">
            <span>or connect with</span>
            <ul className="gx-social-link">
              <li>
                <Icon
                  type="google"
                  onClick={() => {
                    showAuthLoader();
                    userGoogleSignIn();
                  }}
                />
              </li>
              <li>
                <Icon
                  type="facebook"
                  onClick={() => {
                    showAuthLoader();
                    userFacebookSignIn();
                  }}
                />
              </li>
              <li>
                <Icon
                  type="github"
                  onClick={() => {
                    showAuthLoader();
                    userGithubSignIn();
                  }}
                />
              </li>
              <li>
                <Icon
                  type="twitter"
                  onClick={() => {
                    showAuthLoader();
                    userTwitterSignIn();
                  }}
                />
              </li>
            </ul>
          </div>
        </Form>
      </div>
      {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : null}
    </div>
  );
}

SignIn.defaultProps = {
  authUser: null,
}

SignIn.propTypes = {
  authUser: PropTypes.any,
  form: PropTypes.any.isRequired,
  loader: PropTypes.bool.isRequired,
  showAuthLoader: PropTypes.func.isRequired,
  userSignIn: PropTypes.func.isRequired,
  alertMessage: PropTypes.any.isRequired,
  showMessage: PropTypes.any.isRequired,
  hideMessage: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  userFacebookSignIn: PropTypes.func.isRequired,
  userGoogleSignIn: PropTypes.func.isRequired,
  userGithubSignIn: PropTypes.func.isRequired,
  userTwitterSignIn: PropTypes.func.isRequired,
};

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
})(WrappedNormalLoginForm);
