import {
  GET_PAGE,
  GET_PAGE_SUCCESS,
} from '../../constants/ActionTypes';

export const getPage = pageSlug => ({
  type: GET_PAGE,
  payload: pageSlug,
});

export const getPageSuccess = pageData => ({
  type: GET_PAGE_SUCCESS,
  payload: pageData,
});
