import {
  FETCH_PRODUCT_SUCCESS,
  FETCH_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS,
  GET_PRODUCT,
} from '../../constants/ActionTypes';

export const getProduct = slug => ({
  type: GET_PRODUCT,
  payload: slug,
});

export const getProducts = () => ({
  type: GET_ALL_PRODUCTS,
});

export const fetchProductSuccess = product => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: product,
});

export const fetchProductsSuccess = productsList => ({
  type: FETCH_ALL_PRODUCTS_SUCCESS,
  payload: productsList,
});
