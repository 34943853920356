import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FETCH_CHAT_CONTACTS,
  FETCH_CONVERSATION,
  SEND_MESSAGE,
  ADD_MESSAGE
} from 'constants/ActionTypes';

import {
  fetchContactsSuccess,
  fetchConversationSuccess,
  sendMessageSuccess,
  showChatError,
  clearConversation,
  addMessageSuccess
} from '../actions/Chat';

import api from '../../services/api';

const fetchContacts = async () => {
  const response = await api.get('chat/contacts');
  return response.data;
};

function* fetchtContactsByUser() {
  try {
    const contacts = yield call(fetchContacts);
    yield put(fetchContactsSuccess(contacts));
  } catch (error) {
    const { error: err_message } = error.response.data;
    yield put(showChatError(err_message));
  }
}

const fetchConversation = async (userId) => {
  const response = await api.get(`chat/conversation/${userId}`);
  return response.data;
};

function* fetchConversationByUser({ payload }) {
  try {
    yield put(clearConversation());
    const conversation = yield call(fetchConversation, payload);
    yield put(fetchConversationSuccess({ conversation, user: payload }));
  } catch (error) {
    const { error: err_message } = error;
    yield console.log(error)
    yield put(showChatError(err_message));
  }
}

const handleSend = async (message) => {
  const response = await api.post(`chat/conversation/send`, message);
  return response.data;
};

function* handleSendByUser({ payload }) {
  try {
    const message = yield call(handleSend, payload);
    yield put(sendMessageSuccess(message));
  } catch (error) {
    const { error: err_message } = error.response.data;
    yield put(showChatError(err_message));
  }
}

function* handleMessageReceived({ payload }) {
  try {
    yield put(addMessageSuccess(payload));
  } catch (error) {
    const { error: err_message } = error.response.data;
    yield put(showChatError(err_message));
  }
}

export function* getContacts() {
  yield takeEvery(FETCH_CHAT_CONTACTS, fetchtContactsByUser);
}

export function* getConversation() {
  yield takeEvery(FETCH_CONVERSATION, fetchConversationByUser);
}

export function* sendMessaage() {
  yield takeEvery(SEND_MESSAGE, handleSendByUser);
}

export function* addMessage() {
  yield takeEvery(ADD_MESSAGE, handleMessageReceived);
}

export default function* rootSaga() {
  yield all([
    fork(getContacts),
    fork(getConversation),
    fork(sendMessaage),
  ]);
}
