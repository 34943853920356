import {
  STYLES_CONTENT_COURSE,
  STYLES_EDIT_COMMENT,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  styles: false,
  editComment: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STYLES_CONTENT_COURSE: {
      return {
        ...state,
        styles: action.payload,
      };
    }

    case STYLES_EDIT_COMMENT: {
      return {
        ...state,
        editComment: action.payload,
      };
    }

    default:
      return state;
  }
};
