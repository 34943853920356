import React, { memo } from 'react';

import CustomScrollbars from 'util/CustomScrollbars';
import Auxiliary from 'util/Auxiliary';
import Item from './item';
import { itens } from './data';

import { ContainerPurchaseValues } from './styles';

const CartHeader = memo(() => {
  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">My Cart</h3>
        <i className="gx-icon-btn icon icon-charvlet-down" />
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        <ul className="gx-sub-popover">
          {itens.map(item => (
            <Item key={item.id} item={item} />
          ))}
        </ul>
      </CustomScrollbars>
      <ContainerPurchaseValues>
        <p>Sub Total</p>
        <h5>1.403,09</h5>
      </ContainerPurchaseValues>

      <ContainerPurchaseValues>
        <p>Frete</p>
        <h5>59,05</h5>
      </ContainerPurchaseValues>

      <ContainerPurchaseValues>
        <h3>Total</h3>
        <span style={{ color: '#038fde', fontWeight: 580 }}>1.462,14</span>
      </ContainerPurchaseValues>
    </Auxiliary>
  );
});

export default CartHeader;
