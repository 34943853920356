import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Form, Input, Alert } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux';

import {
  forgotPassword,
  hideMessage
} from 'appRedux/actions/Auth';

import logo from '../assets/images/logo_horizontal.png';

const FormItem = Form.Item;

const ForgotPassword = ({ form, forgotPassword, authUser, alertMessage, messageSuccess, showMessage, hideMessage, history }) => {
  useEffect(() => {
    if (authUser !== null) {
      history.push('/');
    }
  }, [authUser, history])

  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (showMessage) {
      setMessage(alertMessage.charAt(0).toUpperCase() + alertMessage.slice(1));
      hideMessage();
    }
    // eslint-disable-next-line
  }, [showMessage, hideMessage]);

  useEffect(() => {
    if (messageSuccess) {
      setSuccessMessage(messageSuccess);
    }
    // eslint-disable-next-line
  }, [messageSuccess])

  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        forgotPassword(values);
      }
    });
  };

  const { getFieldDecorator } = form;

  return (
    <div className="gx-login-container">
      <div
        className="gx-login-content"
        style={{ padding: '10px 35px 20px', position: 'absolute' }}
      >
        <div
          className="gx-login-header gx-text-center"
          style={{ marginBottom: 0 }}
        >
          <img src={logo} alt="" style={{ padding: '30px 0' }} />
        </div>
        <div className="gx-mb-4">
          <h2>
            <IntlMessages id="app.userAuth.forgotQuestion" />
          </h2>
          <p>
            <IntlMessages id="app.userAuth.forgot" />
          </p>
        </div>

        {(message && !successMessage) && (
          <Alert
            message="Error"
            description={message}
            type="error"
            showIcon
            closable
            onClose={() => setMessage(null)}
          />
        )}

        {successMessage ? (
          <>
            <Alert
              message={message}
              description="We've emailed you a link to reset your password. You're one step closer to recovering your password :)"
              type="success"
              showIcon
            />
            <Button type="primary" block size="large" onClick={() => history.push('/login')}>
              Return to login
            </Button>
          </>
        ) : (
          <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
            <FormItem>
            {getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: 'This is not a valid email.',
                },
              ],
            })(
              <Input
                style={{ height: '50px' }}
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                size="large"
                placeholder="Email"
              />
            )}
          </FormItem>

          <FormItem className="gx-text-center">
            <Button type="primary" htmlType="submit" block size="large">
              <IntlMessages id="app.userAuth.reset" />
            </Button>
          </FormItem>
        </Form>
        )}
      </div>
    </div>
  );
};

ForgotPassword.defaultProps = {
  authUser: null,
  messageSuccess: null,
}

ForgotPassword.propTypes = {
  authUser: PropTypes.any,
  messageSuccess: PropTypes.any,
  form: PropTypes.any.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  alertMessage: PropTypes.string.isRequired,
  showMessage: PropTypes.any.isRequired,
  hideMessage: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
};

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, messageSuccess, showMessage, authUser } = auth;
  return { loader, alertMessage, messageSuccess, showMessage, authUser };
};

export default connect(mapStateToProps, {
  forgotPassword,
  hideMessage
})(WrappedForgotPasswordForm);
