import {
  FETCH_ALL_LESSONS_SUCCESS,
  FETCH_INIT_LESSON_SUCCESS,
  FETCH_LESSON_SUCCESS,
  FETCH_LIST_LESSON_SUCCESS,
  FILTER_LESSONS,
  GET_ALL_LESSONS,
  GET_INIT_LESSON,
  GET_LESSON,
  GET_LIST_LESSON,
  SET_ASSISTED_LESSON,
  SET_LESSON_ASSISTED,
  SET_LESSON_ASSISTED_SUCCESS,
  SET_URL_LESSON,
  SET_URL_LESSON_SUCCESS,
} from '../../constants/ActionTypes';

export const setAssitedLesson = (slug, assisted, moduleId) => ({
  type: SET_ASSISTED_LESSON,
  payload: { slug, assisted, moduleId },
});

export const fetchLesson = (moduleSlug, courseSlug, lessonSlug) => ({
  type: GET_LESSON,
  payload: {
    moduleSlug,
    courseSlug,
    lessonSlug,
  },
});

export const getAllLessons = courseSlug => ({
  type: GET_ALL_LESSONS,
  payload: courseSlug,
});

export const fetchAllLessonsSuccess = lessons => ({
  type: FETCH_ALL_LESSONS_SUCCESS,
  payload: lessons,
});

export const fetchListLessonSuccess = lessons => ({
  type: FETCH_LIST_LESSON_SUCCESS,
  payload: lessons,
});

export const fetchInitLessonSuccess = lessons => ({
  type: FETCH_INIT_LESSON_SUCCESS,
  payload: lessons,
});

export const fetchInitLesson = (moduleSlug, courseSlug) => ({
  type: GET_INIT_LESSON,
  payload: {
    moduleSlug,
    courseSlug,
  },
});

export const fetchListLesson = (moduleSlug, courseSlug) => ({
  type: GET_LIST_LESSON,
  payload: {
    moduleSlug,
    courseSlug,
  },
});

export const setLessonAssisted = (courseSlug, moduleSlug, lessonSlug, url) => ({
  type: SET_LESSON_ASSISTED,
  payload: {
    courseSlug,
    moduleSlug,
    lessonSlug,
    url,
  },
});

export const filterLessonsInModules = filter => ({
  type: FILTER_LESSONS,
  payload: { filter },
});

export const setLessonAssistedSuccess = () => ({
  type: SET_LESSON_ASSISTED_SUCCESS,
});

export const fetchLessonSuccess = lesson => ({
  type: FETCH_LESSON_SUCCESS,
  payload: lesson,
});

export const fetchUrlLesson = (slug_course, slug_module, slug_lesson) => ({
  type: SET_URL_LESSON,
  payload: { slug_course, slug_module, slug_lesson },
});

export const fetchUrlLessonSuccess = () => ({
  type: SET_URL_LESSON_SUCCESS,
});
