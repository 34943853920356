import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';

import {
  fetchOneForumSuccess,
  fetchAllForumsSuccess,
  fetchSectionForumSuccess,
  fetchTopicsForumSuccess,
  onGetAllTopicsForum,
} from '../actions/Forum';
import {
  GET_ONE_FORUM,
  GET_ALL_FORUMS,
  GET_ALL_TOPICS_FORUM,
  GET_SECTION_FORUM,
  SET_TOPIC,
} from '../../constants/ActionTypes';

const createTopicRequest = async payload => {
  try {
    const {
      title,
      description,
      tags,
      slugFromTitle,
      slugCategoryForum,
      slugSectionForum,
    } = payload;
    const response = await api.post(
      `forum/${slugCategoryForum}/${slugSectionForum}/topics`,
      {
        name: title,
        description,
        tags,
        slug: slugFromTitle,
      }
    );
    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const getOneForumRequest = async slug => {
  try {
    const response = await api.get(slug.slice(1));
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const getSectionForumRequest = async payload => {
  const { slugCategory, slugSectionForum } = payload;
  try {
    const response = await api.get(`forum/${slugCategory}/${slugSectionForum}`);
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const getAllForumsRequest = async () => {
  try {
    const response = await api.get('forum');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const getAllTopicsForumRequest = async payload => {
  const { slugCategory, slugSectionForum, page } = payload;
  try {
    const response = await api.get(
      `forum/${slugCategory}/${slugSectionForum}/topics?page=${page}`
    );
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

function* onSetTopic({ payload }) {
  const { slugCategory, slugSectionForum } = payload;
  yield call(createTopicRequest, payload);
  yield put(onGetAllTopicsForum(slugCategory, slugSectionForum));
}

function* getOneForum({ payload }) {
  const route = payload.split('/');
  const { length } = route;
  const forum = yield call(getOneForumRequest, payload);
  if (forum) yield put(fetchOneForumSuccess(forum, length));
}

function* getAllForums() {
  const forums = yield call(getAllForumsRequest);
  if (forums) yield put(fetchAllForumsSuccess(forums));
}

function* getAllTopicsForum({ payload }) {
  const topics = yield call(getAllTopicsForumRequest, payload);
  if (topics) yield put(fetchTopicsForumSuccess(topics));
}

function* getSectionForum({ payload }) {
  const sectionForum = yield call(getSectionForumRequest, payload);
  if (sectionForum) yield put(fetchSectionForumSuccess(sectionForum));
}

export function* fetchCreateTopic() {
  yield takeEvery(SET_TOPIC, onSetTopic);
}

export function* fetchOneForum() {
  yield takeEvery(GET_ONE_FORUM, getOneForum);
}

export function* fetchAllForums() {
  yield takeEvery(GET_ALL_FORUMS, getAllForums);
}

export function* fetchAllTopicsForum() {
  yield takeEvery(GET_ALL_TOPICS_FORUM, getAllTopicsForum);
}

export function* fetchSectionForum() {
  yield takeEvery(GET_SECTION_FORUM, getSectionForum);
}

export default function* rootSaga() {
  yield all([
    fork(fetchOneForum),
    fork(fetchCreateTopic),
    fork(fetchAllForums),
    fork(fetchSectionForum),
    fork(fetchAllTopicsForum),
  ]);
}
