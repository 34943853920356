import {
  GET_NOTES,
  UPDATE_NOTE,
  REMOVE_NOTE,
  SET_NOTE,
  SET_NOTE_SUCCESS,
  FETCH_NOTES_SUCCESS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  notes: [],
  notesLesson: [],
  params: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTES: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case UPDATE_NOTE: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case REMOVE_NOTE: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case SET_NOTE: {
      return {
        ...state,
      };
    }

    case SET_NOTE_SUCCESS: {
      return {
        ...state,
        notesLesson: state.notesLesson.concat(action.payload),
      };
    }

    case FETCH_NOTES_SUCCESS: {
      return {
        ...state,
        notes: action.payload,
      };
    }

    default:
      return state;
  }
};
