import styled from 'styled-components';

export const UserProgressContainer = styled.div`
  margin-top: ${({ overflowImage, topbar, zeroMarginTop }) =>
    overflowImage ? '-50px' : topbar || zeroMarginTop ? '0px' : '15px'};
  height: ${({ sizeDefault, mobile }) =>
    sizeDefault ? '63px' : mobile ? '45px' : ''};
  cursor: pointer;

  p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8em;
    text-align: center;
    margin: 10px;
    color: #87d068;
    font-weight: bold;
  }
`;

export const Container = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: ${({ mini, sizeDefault }) =>
    mini || sizeDefault ? 'flex-start' : 'center'};
  height: auto;
  width: 100%;
  margin-left: 0px;
  margin: ${({ autoHeight }) => (autoHeight ? '-20px 0 0 8px' : 0)};
  margin-top: 0;

  .progress-bar {
    z-index: 2;
    position: absolute;
  }

  .ant-card-body {
    padding: 10px;
  }

  .ant-progress-circle {
    margin: 0;
    width: ${({ mini, mobile, sizeDefault }) =>
      mini || mobile ? '42px' : sizeDefault ? '62px' : 'auto'};
    height: ${({ mini, mobile, sizeDefault }) =>
      mini || mobile ? '42px' : sizeDefault ? '62px' : 'auto'};

    .ant-progress-inner {
      width: ${({ mini, mobile, sizeDefault }) =>
        mini || sizeDefault || mobile ? 'auto !important' : ''};
      height: ${({ mini, mobile, sizeDefault }) =>
        mini || sizeDefault || mobile ? 'auto !important' : ''};
    }
  }

  span {
    display: none;
  }

  img {
    z-index: 2;
    margin: ${({ mini, mobile, sizeDefault }) =>
      sizeDefault
        ? '6px 0 0px 6.5px'
        : mobile
        ? '5px 0 0px 0.5px'
        : mini
        ? '5px 0 0px 4.5px'
        : '12.5px 0 0px 0px'};

    width: ${({ mini, mobile, sizeDefault }) =>
      mini || mobile ? '32px' : sizeDefault ? '50px' : '95px'};
    height: ${({ mini, mobile, sizeDefault }) =>
      mini || mobile ? '32px' : sizeDefault ? '50px' : '95px'};
    border-radius: 100px;
  }

  .white-bg {
    display: ${({ mini, mobile, sizeDefault }) =>
      mini || sizeDefault || mobile ? 'none' : 'block'};
    background: #fff;
    margin-top: 1px;
    width: 118px;
    height: 118px;
    border-radius: 100px;
    position: absolute;
  }
`;
