import {
  GET_LEARNING_PATHS_SUCCESS,
  GET_SINGLE_LEARNING_PATH_SUCCESS,
  SET_PROGRESS_OF_CUSTOM_STEP,
  SET_PROGRESS_OF_CUSTOM_STEP_SUCCESS,
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  loader: true,
  learningPaths: null,
  singleLearningPath: null,
  percentageHasUpdated: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LEARNING_PATHS_SUCCESS: {
      return {
        ...state,
        loader: false,
        learningPaths: action.payload,
      };
    }
    case GET_SINGLE_LEARNING_PATH_SUCCESS: {
      return {
        ...state,
        singleLearningPath: action.payload,
      }
    }
    case SET_PROGRESS_OF_CUSTOM_STEP: {
      return {
        ...state,
        percentageHasUpdated: false,
      };
    }
    case SET_PROGRESS_OF_CUSTOM_STEP_SUCCESS: {
      return {
        ...state,
        percentageHasUpdated: action.payload,
      };
    }
    default:
      return state;
  }
};
