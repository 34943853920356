import { GET_TENANCY } from '../../constants/ActionTypes';

const INIT_STATE = {
  tenancy: {},
  domain: null,
  loadingTenancy: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TENANCY: {
      return {
        ...state,
        domain: action.payload,
        loadingTenancy: true,
      };
    }

    default:
      return state;
  }
};
