/* eslint-disable no-console */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';

import {
  fetchOneUser,
  fetchAllDevices,
  fetchAllInterests,
  fetchAllCountries,
  getAllUsersSuccess,
  getAllAuthorsSuccess,
  getAllNonAuthorUsersSuccess,
  followUserSuccess,
  unfollowUserSuccess,
} from '../actions/User';

import {
  GET_USER,
  GET_USER_DEVICES,
  GET_USER_COUNTRIES,
  GET_INTERESTS,
  GET_ALL_USERS,
  GET_ALL_AUTHORS,
  GET_ALL_NON_AUTHOR_USERS,
  FOLLOW_USER,
  UNFOLLOW_USER,
} from '../../constants/ActionTypes';

// GET USER

const getUserRequest = async () => {
  try {
    const response = await api.get('user');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetUser() {
  const user = yield call(getUserRequest);
  if (user) yield put(fetchOneUser(user));
}

export function* fetchUser() {
  yield takeEvery(GET_USER, onGetUser);
}
// GET USER DEVICES

const getUserDevicesRequest = async () => {
  try {
    const response = await api.get('country/json/dropdown');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetUserDevices() {
  const devices = yield call(getUserDevicesRequest);
  if (devices) yield put(fetchAllDevices(devices));
}

export function* fetchDevices() {
  yield takeEvery(GET_USER_DEVICES, onGetUserDevices);
}

// GET USER INTERESTS

const getUserInterestsRequest = async () => {
  try {
    const response = await api.get('user-profile/interest/list');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetUserInterests() {
  const interests = yield call(getUserInterestsRequest);
  if (interests) yield put(fetchAllInterests(interests));
}

export function* fetchInterests() {
  yield takeEvery(GET_INTERESTS, onGetUserInterests);
}

// GET USER COUNTRIES

const getUserCountriesRequest = async () => {
  try {
    const response = await api.get('country/json/dropdown');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetUserCountries() {
  const countries = yield call(getUserCountriesRequest);
  if (countries) yield put(fetchAllCountries(countries));
}

export function* fetchCountries() {
  yield takeEvery(GET_USER_COUNTRIES, onGetUserCountries);
}

// GET ALL USERS

const getAllUsersRequest = async payload => {
  try {
    const response = await api.get(`user-profile/?page=${payload}`);
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetAllUsers({ payload }) {
  const users = yield call(getAllUsersRequest, payload);
  if (users) yield put(getAllUsersSuccess(users));
}

export function* fetchAllUsers() {
  yield takeEvery(GET_ALL_USERS, onGetAllUsers);
}

// GET ALL AUTHORS USERS

const getAllAuthorsRequest = async payload => {
  try {
    const response = await api.get(
      `user-community/authors/?page=${payload}&per_page=5`
    );
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetAllAuthors({ payload }) {
  const authors = yield call(getAllAuthorsRequest, payload);
  if (authors) yield put(getAllAuthorsSuccess(authors));
}

export function* fetchAllAuthors() {
  yield takeEvery(GET_ALL_AUTHORS, onGetAllAuthors);
}

// GET ALL NON-AUTHOR USERS

const getAllNonAuthorUsersRequest = async payload => {
  try {
    const response = await api.get(
      `user-community/users/?page=${payload}&per_page=5`
    );
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetAllNonAuthorUsers({ payload }) {
  const nonAuthorUsers = yield call(getAllNonAuthorUsersRequest, payload);
  if (nonAuthorUsers) yield put(getAllNonAuthorUsersSuccess(nonAuthorUsers));
}

export function* fetchAllNonAuthorUsers() {
  yield takeEvery(GET_ALL_NON_AUTHOR_USERS, onGetAllNonAuthorUsers);
}

// FOLLOW USER

const followUserRequest = async payload => {
  try {
    const response = await api.post(`user/follow/${payload}`);
    return response.data.success;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onFollowUser({ payload }) {
  const succeeded = yield call(followUserRequest, payload);
  if (succeeded) yield put(followUserSuccess(succeeded));
}

export function* fetchFollowUser() {
  yield takeEvery(FOLLOW_USER, onFollowUser);
}

// UNFOLLOW USER

const unfollowUserRequest = async payload => {
  try {
    const response = await api.post(`user/unfollow/${payload}`);
    return response.data.success;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onUnfollowUser({ payload }) {
  const succeeded = yield call(unfollowUserRequest, payload);
  if (succeeded) yield put(unfollowUserSuccess(succeeded));
}

export function* fetchUnfollowUser() {
  yield takeEvery(UNFOLLOW_USER, onUnfollowUser);
}

// EXPORTING SAGAS

export default function* rootSaga() {
  yield all([
    fork(fetchUser),
    fork(fetchDevices),
    fork(fetchCountries),
    fork(fetchInterests),
    fork(fetchAllUsers),
    fork(fetchAllAuthors),
    fork(fetchAllNonAuthorUsers),
    fork(fetchFollowUser),
    fork(fetchUnfollowUser),
  ]);
}
