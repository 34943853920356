/* eslint-disable no-console */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
  delay,
  select,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import {
  fetchLesson,
  fetchModuleSession,
  getAllLessons,
  fetchLessonSuccess,
  fetchModuleSuccess,
  fetchCourseSuccess,
  fetchAllLessonsSuccess,
  fetchListLessonSuccess,
  fetchInitLessonSuccess,
  fetchUrlLessonSuccess,
  setLessonAssistedSuccess,
  fetchSearchLessonsModulesSuccess,
} from 'appRedux/actions';
//import { fetchNotes } from '../actions/Notes';
import { fetchComments } from '../actions/Comment';
import { fetchCourse } from '../actions/Course';
import { fetchModules } from '../actions/Module';
import {
  GET_ALL_LESSONS,
  GET_INIT_LESSON,
  GET_LESSON,
  SET_LESSON_ASSISTED,
  SET_URL_LESSON,
} from '../../constants/ActionTypes';

import api from '../../services/api';

const getLessonRequest = async payload => {
  try {
    const { moduleSlug, courseSlug, lessonSlug } = payload;
    const response = await api.get(
      `course/${courseSlug}/${moduleSlug}/${lessonSlug}`
    );
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
};

const getCourseLessonsRequest = async payload => {
  try {
    const response = await api.get(`course/${payload}/lesson`);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getInitLessonRequest = async payload => {
  try {
    const { moduleSlug, courseSlug } = payload;
    const response = await api.get(`course/${courseSlug}/${moduleSlug}/lesson`);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getLessonAssistedRequest = async payload => {
  const { courseSlug, moduleSlug, lessonSlug } = payload;

  try {
    await api.post(`course/${courseSlug}/${moduleSlug}/${lessonSlug}/assisted`);
  } catch (error) {
    console.log('error -->', error);
  }
};

function* getInitLessons({ payload }) {
  const lessons = yield call(getInitLessonRequest, payload);
  if (lessons) yield put(fetchInitLessonSuccess(lessons));
}

function* getCourseLesson({ payload }) {
  const lessons = yield call(getCourseLessonsRequest, payload);
  if (lessons) {
    yield put(fetchAllLessonsSuccess(lessons));
  } else {
    yield put(fetchAllLessonsSuccess([]));
  }
}

function* getSingleLesson({ payload }) {
  const lesson = yield call(getLessonRequest, payload);
  if (lesson) yield put(fetchLessonSuccess(lesson));
}

function* setLessonAssisted({ payload }) {
  const { url } = payload;
  const lessonAssisted = yield call(getLessonAssistedRequest, payload);
  yield put(setLessonAssistedSuccess());

  if (url !== undefined) {
    yield put(push(url));
  }
  return lessonAssisted;
}

function* setUrlLesson({ payload }) {
  const { slug_course, slug_module, slug_lesson } = payload;
  const { modules, lessons, courses } = yield select();
  const { modulesList } = modules;
  const { courseLessons } = lessons;
  const { course } = courses;

  if (slug_course) {
    if (!modulesList || modulesList.length <= 0) {
      yield put(fetchModules(slug_course));
    }
    if (!course) yield put(fetchCourse(slug_course));
    if (courseLessons.length <= 0) yield put(getAllLessons(slug_course));
    yield put(fetchLesson(slug_module, slug_course, slug_lesson));
    yield put(fetchComments(slug_course, slug_module, slug_lesson));
    yield put(fetchModuleSession(slug_course, slug_module));
    //yield put(fetchNotes(slug_lesson));
    yield delay(2000);
    yield put(fetchUrlLessonSuccess());
  } else {
    yield put(fetchCourseSuccess(null));
    yield put(fetchModuleSuccess([]));
    yield put(fetchLessonSuccess({}));
    yield put(fetchAllLessonsSuccess([]));
    yield put(fetchListLessonSuccess([]));
    yield put(fetchSearchLessonsModulesSuccess([]));
  }
}

export function* fetchCourseLessonsA() {
  yield takeEvery(GET_ALL_LESSONS, getCourseLesson);
}

export function* fetchInitLessons() {
  yield takeEvery(GET_INIT_LESSON, getInitLessons);
}

export function* fetchSingleLesson() {
  yield takeEvery(GET_LESSON, getSingleLesson);
}

export function* fetchLessonAssisted() {
  yield takeEvery(SET_LESSON_ASSISTED, setLessonAssisted);
}

export function* setUrlLessons() {
  yield takeLatest(SET_URL_LESSON, setUrlLesson);
}

export default function* rootSaga() {
  yield all([
    fork(fetchSingleLesson),
    fork(fetchComments),
    fork(fetchLessonAssisted),
    fork(setUrlLessons),
    fork(fetchInitLessons),
    fork(fetchCourseLessonsA),
  ]);
}
