import React from 'react';
import { FaAward, FaStar } from 'react-icons/fa';
import { AiFillDollarCircle } from 'react-icons/ai';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';

import Widget from 'components/Widget';
import UserProgress from '../../../../../componentsCustom/UserProgress';
import { CardContainer, InfoContainer, InsigniasContainer } from './styles';

const Status = ({ dataFromApi, autoHeight = false }) => {
  const {
    name,
    image,
    credits,
    nextLevel,
    percent,
    level,
    points,
    ranking,
    data,
  } = dataFromApi;
  return (
    <CardContainer autoHeight={autoHeight}>
      <Widget styleName="gx-card-full">
        <div className="gx-widget-badge points">
          <AiFillDollarCircle className="fa-coins" />
          <p>{credits}</p>
        </div>
        <UserProgress percent={percent} image={image} />
        <div className="gx-text-center gx-px-3 gx-pt-3">
          <div className="gx-mb-3">
            <h3>{name}</h3>
            <p className="default-text">
              {`Próximo Level em ${nextLevel} pts (${percent}% concluído)`}
            </p>
          </div>
          <InfoContainer>
            <FaAward className="fa-award" />
            <div className="text">
              <p>{`Level: ${level}`}</p>
              <p>{`Pontos: ${points}`}</p>
              <p>{`Ranking: ${ranking}`}</p>
            </div>
          </InfoContainer>
          <InsigniasContainer>
            <FaStar className="fa-star" />
            <FaStar className="fa-star" />
            <FaStar className="fa-star" />
            <FaStar className="fa-star bw" />
            <FaStar className="fa-star bw" />
            <FaStar className="fa-star bw" />
          </InsigniasContainer>
        </div>
        <div className="gx-rechart">
          <p className="default-text">Pontos em 30 dias</p>
          <ResponsiveContainer width="100%" height={118}>
            <AreaChart
              data={data}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <Area
                type="monotone"
                dataKey="price"
                stackId="2"
                stroke="#4D95F3"
                fill="#038FDE"
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Widget>
    </CardContainer>
  );
};

export default Status;
