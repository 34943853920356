import styled from 'styled-components';

export const Container = styled.div`
  
  @media (min-width: 1200px) {
    width: 1050px;
    margin: 0 auto;
  }
`;

export const Input = styled.input`
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgb(233, 233, 233);
    border-radius: 10px;
`;

export const InputContainer = styled.div`
    display: -ms-flex;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 0;
    transition: width 2s;
`;
