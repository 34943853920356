import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Blog from './Blog';
import Contact from './Contact';
import Common from './Common';
import Course from './Course';
import Custom from './Custom';
import Collection from './Collection';
import Module from './Module';
import Lesson from './Lesson';
import Notes from './Notes';
import Comment from './Comment';
import Product from './Product';
import User from './User';
import Checkout from './Checkout';
import Tenancy from './Tenancy';
import Menu from './Menu';
import Todo from './Todo';
import Forum from './Forum';
import LearningPath from './LearningPath';
import Page from './Page';
import Chat from './Chat';
import CustomScripts from './CustomScripts';
import Denounce from './Denounce';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    blog: Blog,
    contact: Contact,
    common: Common,
    courses: Course,
    comments: Comment,
    collections: Collection,
    modules: Module,
    lessons: Lesson,
    notes: Notes,
    products: Product,
    user: User,
    checkout: Checkout,
    tenancy: Tenancy,
    dynamicMenu: Menu,
    custom: Custom,
    todo: Todo,
    forum: Forum,
    learningPath: LearningPath,
    page: Page,
    chat: Chat,
    customScripts: CustomScripts,
    denounce: Denounce,
  });

export default createRootReducer;

// *auth: Auth,
//     *blog: Blog,
//     contact: Contact,
//     common: Common,
//     *courses: Course,
//     *modules: Module,
//     *lessons: Lesson,
//     *comments: Comment,
//     *notes: Notes,
//     *product: Product,
//     *user: User,
//     checkout: Checkout,
//     tenancy: Tenancy,
//     *dynamicMenu: Menu,
//     config: Config,
//     *todo: Todo,
