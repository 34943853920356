import {
  FETCH_ALL_COURSES_SUCCESS,
  FETCH_SEARCH_LESSONS_MODULES_SUCCESS,
  FETCH_COURSE_SUCCESS,
  GET_COURSE,
  UPDATE_PERCENTAGE,
  SET_SEARCH_LESSONS_MODULES,
  GET_COURSE_REVIEWS_SUCCESS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  coursesList: [],
  resourcesFilter: [],
  course: null,
  courseReviews: null,
  params: {},
  paramsFilter: {},
  loader: true,
  loaderFilter: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COURSE: {
      return {
        ...state,
        loader: true,
        params: action.payload,
      };
    }

    case GET_COURSE_REVIEWS_SUCCESS: {
      return {
        ...state,
        courseReviews: action.payload,
      };
    }

    case SET_SEARCH_LESSONS_MODULES: {
      return {
        ...state,
        loaderFilter: true,
        paramsFilter: action.payload,
      };
    }

    case UPDATE_PERCENTAGE: {
      const { moduleId, courseLessons, modules } = action.payload;

      const countCourseAssisted = courseLessons.filter(
        lesson => lesson.user_assisted
      ).length;

      const countCourseLesson = courseLessons.length;

      const newCoursePercentageCalc =
        (countCourseAssisted / countCourseLesson) * 100;

      const newCourse = { ...state.course };
      newCourse.complete_percentage = newCoursePercentageCalc;

      const countModuleAssisted = courseLessons.filter(
        lesson => lesson.user_assisted && lesson.module_id === moduleId
      ).length;

      const countModuleLesson = courseLessons.filter(
        lesson => lesson.module_id === moduleId
      ).length;

      const newModulePercentageCalc =
        (countModuleAssisted / countModuleLesson) * 100;

      const newModule = modules.map(item => {
        const moduleItem = item;

        if (item.id === moduleId) {
          moduleItem.complete_percentage = newModulePercentageCalc;
        }
        return moduleItem;
      });

      return {
        ...state,
        course: newCourse,
        modules: newModule,
      };
    }

    case FETCH_COURSE_SUCCESS: {
      return {
        ...state,
        loader: false,
        course: action.payload,
      };
    }

    case FETCH_ALL_COURSES_SUCCESS: {
      return {
        ...state,
        loader: false,
        coursesList: action.payload,
      };
    }

    case FETCH_SEARCH_LESSONS_MODULES_SUCCESS: {
      return {
        ...state,
        loaderFilter: false,
        resourcesFilter: action.payload,
      };
    }

    default:
      return state;
  }
};
