import {
  SELECT_CATEGORY,
  FETCH_DYNAMIC_BLOG_SUCCESS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  blogList: [],
  blogPost: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DYNAMIC_BLOG_SUCCESS: {
      if (action.length === 2) {
        return {
          ...state,
          blogPost: action.payload,
        };
      }
      return {
        ...state,
        blogList: action.payload.reverse(),
      };
    }

    case SELECT_CATEGORY: {
      return {
        ...state,
        filterCategories: Array.from(action.payload),
      };
    }

    default:
      return state;
  }
};
