import {
    FETCH_CHAT_CONTACTS,
    FETCH_CHAT_CONTACTS_SUCCESS,
    FETCH_CONVERSATION,
    FETCH_CONVERSATION_SUCCESS,
    CLEAR_CONVERSATION,
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    SHOW_CHAT_ERROR,
    ADD_MESSAGE,
    ADD_MESSAGE_SUCCESS
} from 'constants/ActionTypes';

export const fetchContacts = () => {
    return {
        type: FETCH_CHAT_CONTACTS
    };
};

export const fetchContactsSuccess = contacts => {
    return {
        type: FETCH_CHAT_CONTACTS_SUCCESS,
        payload: contacts,
    };
};

export const fetchConversation = userId => {
    return {
        type: FETCH_CONVERSATION,
        payload: userId,
    };
};

export const addMessage = message => {
    return {
        type: ADD_MESSAGE,
        payload: message,
    };
};

export const addMessageSuccess = message => {
    return {
        type: ADD_MESSAGE_SUCCESS,
        payload: message,
    };
};

export const fetchConversationSuccess = payload => {
    return {
        type: FETCH_CONVERSATION_SUCCESS,
        payload: {
            conversation: payload.conversation,
            currentConversationUser: payload.user
        },
    };
};

export const clearConversation = () => {
    return {
        type: CLEAR_CONVERSATION,
    };
};

export const sendMessage = message => {
    return {
        type: SEND_MESSAGE,
        payload: message,
    };
};

export const sendMessageSuccess = response => {
    return {
        type: SEND_MESSAGE_SUCCESS,
        payload: response ,
    };
};

export const showChatError = mensageError => {
    return {
        type: SHOW_CHAT_ERROR,
        payload: mensageError,
    };
}; 