/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SET_DENOUNCE } from '../../constants/ActionTypes';
import { setDenounceSuccess } from '../actions/Denounce';
import api from '../../services/api';

const setDenounceRequest = async payload => {
  const { userId, reportedUserId, type, description } = payload;
  try {
    const response = await api.post('report', {
      reportgable_type: '\\Deskti\\Comments\\Models\\Comment',
      reportgable_id: userId,
      reported_user_id: reportedUserId,
      type,
      description,
    });
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onSetDenounce({ payload }) {
  const response = yield call(setDenounceRequest, payload);
  if (response) yield put(setDenounceSuccess());
}

export function* setDenounce() {
  yield takeEvery(SET_DENOUNCE, onSetDenounce);
}

export default function* rootSaga() {
  yield all([fork(setDenounce)]);
}
