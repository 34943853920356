import {
  FETCH_COMMENTS_SUCCESS,
  UPDATE_COMMENT_SUCCESS,
  REMOVE_COMMENT_SUCCESS,
  GET_COMMENTS,
  UPDATE_COMMENT,
  REMOVE_COMMENT,
  SET_COMMENT,
  SET_COMMENT_ID_REPLY,
  SET_COMMENT_SUCCESS,
  SET_COMMENT_LIKE,
  SET_COMMENT_LIKE_SUCCESS,
  SET_COMMENT_SUCCESS_API,
} from '../../constants/ActionTypes';

export const fetchComments = (courseSlug, moduleSlug, lessonSlug) => ({
  type: GET_COMMENTS,
  payload: {
    courseSlug,
    moduleSlug,
    lessonSlug,
  },
});

export const setCommentIdReply = id => ({
  type: SET_COMMENT_ID_REPLY,
  payload: id,
});

export const setCommentLike = (
  courseSlug,
  moduleSlug,
  lessonSlug,
  id,
  action
) => ({
  type: SET_COMMENT_LIKE,
  payload: { courseSlug, moduleSlug, lessonSlug, id, action },
});

export const setCommentLikeSuccess = () => ({
  type: SET_COMMENT_LIKE_SUCCESS,
});

export const setComment = (
  courseSlug,
  moduleSlug,
  lessonSlug,
  comment,
  parent,
  totalComments
) => ({
  type: SET_COMMENT,
  payload: {
    courseSlug,
    moduleSlug,
    lessonSlug,
    comment,
    parent,
    totalComments,
  },
});

export const fetchCommentSuccess = (comments, totalComments) => ({
  type: FETCH_COMMENTS_SUCCESS,
  payload: { comments, totalComments },
});

export const setCommentSuccess = comment => ({
  type: SET_COMMENT_SUCCESS,
  payload: comment,
});

export const setCommentSuccessApi = comment => ({
  type: SET_COMMENT_SUCCESS_API,
  payload: comment,
});

export const setUpdateComment = (
  id,
  userId,
  comment,
  courseSlug,
  moduleSlug,
  lessonSlug,
  commentChildren
) => ({
  type: UPDATE_COMMENT,
  payload: {
    id,
    userId,
    comment,
    courseSlug,
    moduleSlug,
    lessonSlug,
    commentChildren,
  },
});

export const updateCommentSuccess = comments => ({
  type: UPDATE_COMMENT_SUCCESS,
  payload: comments,
});

export const removeComment = (
  courseSlug,
  moduleSlug,
  lessonSlug,
  comment,
  totalComments
) => ({
  type: REMOVE_COMMENT,
  payload: { courseSlug, moduleSlug, lessonSlug, comment, totalComments },
});

export const removeCommentSuccess = comments => ({
  type: REMOVE_COMMENT_SUCCESS,
  payload: comments,
});
