import axios from 'axios';
import { getToken } from './auth';

/**
 * Api interactor Axios
 */
const api = axios.create({
  baseURL: 'https://magicaonline.ensinio.cloud/api/v1/',
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

/**
 * Base protocol path api
 * @type {{course: {get: string}}}
 * @type {{module: {get: string}}}
 */

export default api;
