// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const STYLES_CONTENT_COURSE = 'STYLES_CONTENT_COURSE';
export const STYLES_EDIT_COMMENT = 'STYLES_EDIT_COMMENT';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

// Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Auth const
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SIGNUP_USER = 'SIGNUP_USER';
export const CONFIRM_USER_EMAIL = 'CONFIRM_USER_EMAIL';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

// Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

// Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

// Tab
export const TAB_ACTIVE = 'TAB_ACTIVE';

// Courses
export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_COURSE = 'GET_COURSE';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const FETCH_ALL_COURSES_SUCCESS = 'FETCH_ALL_COURSES_SUCCESS';
export const VISUALIZATION_MODE = 'VISUALIZATION_MODE';
export const UPDATE_PERCENTAGE = 'UPDATE_PERCENTAGE';
export const GET_COURSE_REVIEWS = 'GET_COURSE_REVIEWS';
export const GET_COURSE_REVIEWS_SUCCESS = 'GET_COURSE_REVIEWS_SUCCESS';

// Module 
export const GET_MODULES = 'GET_MODULES';
export const GET_MODULE_SESSION = 'GET_MODULE_SESSION';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';
export const FETCH_MODULES_SESSION_SUCCESS = 'FETCH_MODULES_SESSION_SUCCESS';

// Lesson
export const GET_ALL_LESSONS = 'GET_ALL_LESSONS';
export const GET_LESSON = 'GET_LESSON';
export const GET_COMMENTS = 'GET_COMMENTS';
export const FETCH_LESSON_SUCCESS = 'FETCH_LESSON_SUCCESS';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const SET_COMMENT = 'SET_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const REMOVE_COMMENT_SUCCESS = 'REMOVE_COMMENT_SUCCESS';
export const SET_COMMENT_SUCCESS = 'SET_COMMENT_SUCCESS';
export const SET_COMMENT_SUCCESS_API = 'SET_COMMENT_SUCCESS_API';
export const SET_LESSON_ASSISTED = 'SET_LESSON_ASSISTED';
export const SET_LESSON_ASSISTED_SUCCESS = 'SET_LESSON_ASSISTED_SUCCESS';
export const SET_URL_LESSON = 'SET_URL_LESSON';
export const SET_URL_LESSON_SUCCESS = 'SET_URL_LESSON_SUCCESS';
export const SET_NOTE = 'SET_NOTE';
export const SET_NOTE_SUCCESS = 'SET_NOTE_SUCCESS';
export const GET_INIT_LESSON = 'GET_INIT_LESSON';
export const GET_LIST_LESSON = 'GET_LIST_LESSON';
export const FETCH_INIT_LESSON_SUCCESS = 'FETCH_INIT_LESSON_SUCCESS';
export const FETCH_LIST_LESSON_SUCCESS = 'FETCH_LIST_LESSON_SUCCESS';
export const GET_NOTES = 'GET_NOTES';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const REMOVE_NOTE = 'REMOVE_NOTE';
export const FETCH_ALL_LESSONS_SUCCESS = 'FETCH_ALL_LESSONS_SUCCESS';
export const SET_ASSISTED_LESSON = 'SET_ASSISTED_LESSON';
export const SET_LOADING_LAND_PAGE = 'SET_LOADING_LAND_PAGE';
export const FILTER_LESSONS = 'FILTER_LESSONS';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const SET_COMMENT_ID_REPLY = 'SET_COMMENT_ID_REPLY';
export const SET_COMMENT_LIKE = 'SET_COMMENT_LIKE';
export const SET_COMMENT_LIKE_SUCCESS = 'SET_COMMENT_LIKE_SUCCESS';

// PRODUCT
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const FETCH_ALL_PRODUCTS_SUCCESS = 'FETCH_ALL_PRODUCTS_SUCCESS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';

// User
export const SET_PAGE = 'SET_PAGE';
export const GET_USER = 'GET_USER';
export const GET_INTERESTS = 'GET_INTERESTS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER_DEVICES = 'GET_USER_DEVICES';
export const GET_USER_COUNTRIES = 'GET_USER_COUNTRIES';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_AUTHORS = 'GET_ALL_AUTHORS';
export const GET_ALL_AUTHORS_SUCCESS = 'GET_ALL_AUTHORS_SUCCESS';
export const GET_ALL_NON_AUTHOR_USERS = 'GET_ALL_NON_AUTHOR_USERS';
export const GET_ALL_NON_AUTHOR_USERS_SUCCESS =
  'GET_ALL_NON_AUTHOR_USERS_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_INTERESTS_SUCCESS = 'FETCH_INTERESTS_SUCCESS';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FOLLOW_USER = 'FOLLOW_USER';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';

// Checkout
export const SET_POST_OFFICES = 'SET_POST_OFFICES';

// Tenancy
export const GET_TENANCY = 'GET_TENANCY';

// GAMIFICATION
export const GET_DATA = 'GET_DATA';

// DYNAMIC MENU
export const GET_MAIN_MENU = 'GET_MAIN_MENU';
export const GET_PROFILE_MENU = 'GET_PROFILE_MENU';
export const GET_FOOTER_MENU = 'GET_FOOTER_MENU';
export const GET_MENU_SHORTCUTS = 'GET_MENU_SHORTCUTS';
export const FETCH_MAIN_MENU_SUCCESS = 'FETCH_MAIN_MENU_SUCCESS';
export const FETCH_PROFILE_MENU_SUCCESS = 'FETCH_PROFILE_MENU_SUCCESS';
export const FETCH_FOOTER_MENU_SUCCESS = 'FETCH_FOOTER_MENU_SUCCESS';
export const FETCH_MENU_SHORTCUTS_SUCCESS = 'FETCH_MENU_SHORTCUTS_SUCCESS';

// DYNAMIC TODO
export const GET_TODO = 'GET_TODO';
export const FETCH_DYNAMIC_TODO_SUCCESS = 'FETCH_DYNAMIC_TODO_SUCCESS';

// DYNAMIC BLOG
export const GET_BLOG = 'GET_BLOG';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const FETCH_DYNAMIC_BLOG_SUCCESS = 'FETCH_DYNAMIC_BLOG_SUCCESS';

// DENOUNCE
export const SET_DENOUNCE = 'SET_DENOUNCE';
export const SET_DENOUNCE_SUCCESS = 'SET_DENOUNCE_SUCCESS';
export const CHANGE_DENOUNCE_SUCCESS_STATE = 'CHANGE_DENOUNCE_SUCCESS_STATE';

// FORUM
export const ROUTE_FROM_FORUM_CATEGORIES = 'ROUTE_FROM_FORUM_CATEGORIES';
export const SET_TOPIC = 'SET_TOPIC';
export const GET_ALL_FORUMS = 'GET_ALL_FORUMS';
export const GET_ALL_TOPICS_FORUM = 'GET_ALL_TOPICS_FORUM';
export const GET_SECTION_FORUM = 'GET_SECTION_FORUM';
export const FETCH_ALL_FORUMS_SUCCESS = 'FETCH_ALL_FORUMS_SUCCESS';
export const GET_ONE_FORUM = 'GET_ONE_FORUM';
export const FETCH_ONE_FORUM_SUCCESS = 'FETCH_ONE_FORUM_SUCCESS';
export const FETCH_SECTION_FORUM_SUCCESS = 'FETCH_SECTION_FORUM_SUCCESS';
export const FETCH_ALL_TOPICS_FORUM_SUCCESS = 'FETCH_ALL_TOPICS_FORUM_SUCCESS';

// COLLECTIONS
export const GET_ALL_COLLECTIONS = 'GET_ALL_COLLECTIONS';
export const FETCH_ALL_COLLECTIONS_SUCCESS = 'FETCH_ALL_COLLECTIONS_SUCCESS';
export const FETCH_COLLECTION_SESSION_SUCCESS =
  'FETCH_COLLECTION_SESSION_SUCCESS';
export const SET_COLLECTION = 'SET_COLLECTION';
export const SET_COLLECTION_POSITION = 'SET_COLLECTION_POSITION';
export const SET_COLLECTION_ITEM_POSITION = 'SET_COLLECTION_ITEM_POSITION';
export const SET_PRIVATE_COLLECTION = 'SET_PRIVATE_COLLECTION';
export const SET_COLLECTION_SESSION = 'SET_COLLECTION_SESSION';
export const SET_COLLECTION_TYPE = 'SET_COLLECTION_TYPE';
export const SET_ITEMS_COLLECTIONS = 'SET_ITEMS_COLLECTIONS';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const REMOVE_ITEM_COLLECTIONS = 'REMOVE_ITEM_COLLECTIONS';
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION';

// LEARNING PATHS
export const GET_LEARNING_PATHS = 'GET_LEARNING_PATHS';
export const GET_LEARNING_PATHS_SUCCESS = 'GET_LEARNING_PATHS_SUCCESS';
export const GET_SINGLE_LEARNING_PATH = 'GET_SINGLE_LEARNING_PATH';
export const GET_SINGLE_LEARNING_PATH_SUCCESS =
  'GET_SINGLE_LEARNING_PATH_SUCCESS';
export const SET_PROGRESS_OF_CUSTOM_STEP = 'SET_PROGRESS_OF_CUSTOM_STEP';
export const SET_PROGRESS_OF_CUSTOM_STEP_SUCCESS =
  'SET_PROGRESS_OF_CUSTOM_STEP_SUCCESS';

// PAGE
export const GET_PAGE = 'GET_PAGE';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';

// SEARCH
export const SET_SEARCH_LESSONS_MODULES = 'SET_SEARCH_LESSONS_MODULES';
export const FETCH_SEARCH_LESSONS_MODULES_SUCCESS =
  'FETCH_SEARCH_LESSONS_MODULES_SUCCESS';

// CHAT
export const SHOW_CHAT_ERROR = 'SHOW_CHAT_ERROR';
export const FETCH_CHAT_CONTACTS = 'FETCH_CHAT_CONTACTS';
export const FETCH_CHAT_CONTACTS_SUCCESS = 'FETCH_CHAT_CONTACTS_SUCCESS';
export const FETCH_CONVERSATION = 'FETCH_CONVERSATION';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE = 'ADD_MESSAGE';

// CUSTOM SCRIPTS
export const GET_CUSTOM_SCRIPTS = 'GET_CUSTOM_SCRIPTS';
export const GET_CUSTOM_SCRIPTS_SUCCESS = 'GET_CUSTOM_SCRIPTS_SUCCESS';
