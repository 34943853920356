/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getCustomScriptsSuccess } from '../actions/CustomScripts';
import {
  GET_CUSTOM_SCRIPTS,
} from '../../constants/ActionTypes';
import api from '../../services/api';

const getCustomScriptsRequest = async () => {
  try {
    const { data } = await api.get(`custom-script`);
    return data;
  } catch (error) {
    console.log('error -->', error);
  }
};

function* getCustomScripts() {
  const scripts = yield call(getCustomScriptsRequest);
  if (scripts) yield put(getCustomScriptsSuccess(scripts));
}

export function* fetchCustomScripts() {
  yield takeEvery(GET_CUSTOM_SCRIPTS, getCustomScripts);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCustomScripts),
  ]);
}
