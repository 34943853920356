import {
  GET_ONE_FORUM,
  GET_SECTION_FORUM,
  FETCH_ONE_FORUM_SUCCESS,
  FETCH_ALL_FORUMS_SUCCESS,
  FETCH_SECTION_FORUM_SUCCESS,
  FETCH_ALL_TOPICS_FORUM_SUCCESS,
  ROUTE_FROM_FORUM_CATEGORIES,
  SET_TOPIC,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  topic: true,
  topicsInForum: [],
  forumBySlug: null,
  topicBySlug: null,
  forumsInCategories: [],
  createTopicRoute: null,
  params: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROUTE_FROM_FORUM_CATEGORIES: {
      return {
        ...state,
        createTopicRoute: action.payload,
      };
    }

    case GET_ONE_FORUM: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case GET_SECTION_FORUM: {
      return {
        ...state,
        loader: true,
      };
    }

    case SET_TOPIC: {
      return {
        ...state,
        topicsInForum: state.topicsInForum.concat(action.payload),
        loader: true,
      };
    }

    case FETCH_ONE_FORUM_SUCCESS: {
      if (action.route === 3) {
        return {
          ...state,
          topicsInForum: action.payload,
          topic: true,
        };
      }
      if (action.route === 6) {
        return {
          ...state,
          topicBySlug: action.payload,
          topic: false,
        };
      }
      return {
        ...state,
        forumBySlug: action.payload,
      };
    }

    case FETCH_ALL_FORUMS_SUCCESS: {
      return {
        ...state,
        forumsInCategories: action.payload,
        loader: false,
      };
    }

    case FETCH_SECTION_FORUM_SUCCESS: {
      return {
        ...state,
        forumBySlug: action.payload,
        loader: false,
      };
    }

    case FETCH_ALL_TOPICS_FORUM_SUCCESS: {
      return {
        ...state,
        topicsInForum: action.payload,
        loader: false,
      };
    }

    default:
      return state;
  }
};
