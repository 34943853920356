import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { css, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';

import asyncComponent from 'util/asyncComponent';
import { isMobile } from '../config/consts';
// import PageTopicBlog from './Blog/pages/PageTopicBlog';

const GlobalStyles = createGlobalStyle`
  .ant-timeline-item {
    padding-bottom: 15px !important;
  }

  .ant-drawer-content-wrapper {
    background: #2d3e50 !important;
  }

  .card-style {
    background: #fff;
    border-radius: 20px;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.05);
    padding: 15px;
  }

  .soft-bold {
    font-weight: 600;
    display: inline-block;
  }
`;

const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  ${props =>
    props.stylesContentCourse
      ? css`
          padding: 15px 15px 0px 15px;
        `
      : ''}

  ${props =>
    props.isMobile
      ? css`
          background-color: #fff;
        `
      : ''}

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #003366;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #003366;
  }
`;

const App = ({ match }) => {
  const stylesContentCourse = useSelector(({ custom }) => custom.styles);

  return (
    <Container
      className="gx-main-content-wrapper"
      stylesContentCourse={stylesContentCourse}
      isMobile={isMobile}
    >
      <GlobalStyles />
      <Switch>
        <Route
          path={`${match.url}tester`}
          component={asyncComponent(() => import('./Tester'))}
        />

        <Route
          path={`${match.url}profile`}
          component={asyncComponent(() => import('./User/Profile'))}
        />

        <Route
          path={`${match.url}user/:section/:action?/:id?`}
          component={asyncComponent(() => import('./User/ProfileEdit'))}
        />
        <Route
          path={`${match.url}dashboard/crm`}
          component={asyncComponent(() => import('./main/dashboard'))}
        />

        <Route
          exact
          path={`${match.url}course`}
          component={asyncComponent(() => import('./Courses'))}
        />

        <Route
          exact
          path={`${match.url}course/:slug_course`}
          component={asyncComponent(() =>
            import('./Courses/CourseLandingPage')
          )}
        />

        <Route
          exact
          path={`${match.url}course/:slug_course/:slug_module/:slug_lesson`}
          component={asyncComponent(() => import('./Courses/CourseContent'))}
        />

        <Route
          exact
          path={`${match.url}blog`}
          component={asyncComponent(() => import('./Blog'))}
        />

        <Route
          exact
          path={`${match.url}dashboard`}
          component={asyncComponent(() =>
            import('./Gamification/pages/InitialPanel')
          )}
        />

        <Route
          exact
          path={`${match.url}contentPanel`}
          component={asyncComponent(() =>
            import('./Gamification/pages/ContentPanel')
          )}
        />

        <Route
          exact
          path={`${match.url}gamificationPanel`}
          component={asyncComponent(() =>
            import('./Gamification/pages/GamificationPanel')
          )}
        />

        <Route
          exact
          path={`${match.url}usersCommunity`}
          component={asyncComponent(() =>
            import('./Gamification/pages/UsersCommunity/index')
          )}
        />

        <Route
          exact
          path={`${match.url}blog/:magica`}
          component={asyncComponent(() =>
            import('./Blog/pages/PageTopicBlog/index')
          )}
        />

        <Route
          exact
          path={`${match.url}forum`}
          component={asyncComponent(() =>
            import('./Forum/pages/forumCategories')
          )}
        />

        <Route
          exact
          path={`${match.url}forum/:category/:topics`}
          component={asyncComponent(() => import('./Forum/pages/forumSection'))}
        />

        <Route
          exact
          path={`${match.url}forum/:category/:forum/topics/:topic`}
          component={asyncComponent(() => import('./Forum/pages/forumDetails'))}
        />

        <Route
          path={`${match.url}product`}
          component={asyncComponent(() =>
            import('./eCommerce/Product/ProductList')
          )}
        />

        <Route
          path={`${match.url}productItem`}
          component={asyncComponent(() =>
            import('./eCommerce/Product/ProductInfo')
          )}
        />

        <Route
          path={`${match.url}premium`}
          component={asyncComponent(() =>
            import('./eCommerce/Premium/PremiumList')
          )}
        />

        <Route
          path={`${match.url}premiumItem`}
          component={asyncComponent(() =>
            import('./eCommerce/Premium/PremiumInfo')
          )}
        />

        <Route
          path={`${match.url}learningPaths/:learningPath`}
          exact
          component={asyncComponent(() => import('./SingleLearningPath'))}
        />

        <Route
          path={`${match.url}learningPaths`}
          exact
          component={asyncComponent(() => import('./LearningPaths'))}
        />

        <Route
          path={`${match.url}formAddress`}
          component={asyncComponent(() =>
            import('./eCommerce/Checkout/FormAddress')
          )}
        />

        <Route
          path={`${match.url}shippingSplit`}
          component={asyncComponent(() =>
            import('./eCommerce/Checkout/ShippingSplit')
          )}
        />

        <Route
          path={`${match.url}singleShippingOptions`}
          component={asyncComponent(() =>
            import('./eCommerce/Checkout/SingleShippingOptions')
          )}
        />

        <Route
          path={`${match.url}payments`}
          component={asyncComponent(() =>
            import('./eCommerce/Checkout/Payments')
          )}
        />

        <Route
          path={`${match.url}creditCard`}
          component={asyncComponent(() =>
            import('./eCommerce/Checkout/CreditCard')
          )}
        />

        <Route
          path={`${match.url}bankSlip`}
          component={asyncComponent(() =>
            import('./eCommerce/Checkout/BankSlip')
          )}
        />

        <Route
          path={`${match.url}singleReview`}
          component={asyncComponent(() =>
            import('./eCommerce/Checkout/SingleReview')
          )}
        />

        <Route
          path={`${match.url}congrats`}
          component={asyncComponent(() =>
            import('./eCommerce/Checkout/Congrats')
          )}
        />

        <Route
          path={`${match.url}cart`}
          component={asyncComponent(() => import('./eCommerce/Cart'))}
        />

        <Route
          path={`${match.url}newsFeed`}
          component={asyncComponent(() => import('./NewsFeed'))}
        />

        <Route
          path={`${match.url}newsFeedItem`}
          component={asyncComponent(() => import('./NewsFeed/NewFeedItem'))}
        />

        <Route
          path={`${match.url}notes`}
          component={asyncComponent(() => import('./Notes'))}
        />

        <Route
          path={`${match.url}activityFeed`}
          component={asyncComponent(() => import('./ActivityFeed'))}
        />

        <Route
          path={`${match.url}widgetPage`}
          component={asyncComponent(() => import('./WidgetPage'))}
        />

        <Route
          path={`${match.url}404`}
          component={asyncComponent(() => import('./PagesNotify/404'))}
        />

        <Route
          path={`${match.url}500`}
          component={asyncComponent(() => import('./PagesNotify/500'))}
        />

        <Route
          path={`${match.url}maintenance`}
          component={asyncComponent(() => import('./PagesNotify/Maintenance'))}
        />

        <Route
          exact
          path={`${match.url}collections`}
          component={asyncComponent(() => import('./Collections'))}
        />

        <Route
          exact
          path={`${match.url}collections/:id`}
          component={asyncComponent(() => import('./Collections/Collection'))}
        />

        <Route
          path={`${match.url}redeem01`}
          component={asyncComponent(() => import('./Redeem/pages/Step01'))}
        />
        <Route
          path={`${match.url}redeem02`}
          component={asyncComponent(() => import('./Redeem/pages/Step02'))}
        />
        <Route
          path={`${match.url}redeem03`}
          component={asyncComponent(() => import('./Redeem/pages/Step03'))}
        />
        <Route
          path={`${match.url}myOrders`}
          component={asyncComponent(() => import('./Orders/pages/MyOrders'))}
        />
        <Route
          path={`${match.url}orderDetails`}
          component={asyncComponent(() =>
            import('./Orders/pages/OrderDetails')
          )}
        />
        <Route
          path={`${match.url}mySubscriptions`}
          component={asyncComponent(() =>
            import('./Subscriptions/pages/MySubscriptions')
          )}
        />
        <Route
          path={`${match.url}subscriptionDetails`}
          component={asyncComponent(() =>
            import('./Subscriptions/pages/SubscriptionDetails')
          )}
        />
        <Route
          path={`${match.url}subscriptionPlans`}
          component={asyncComponent(() =>
            import('./Subscriptions/pages/SubscriptionPlans')
          )}
        />

        <Route
          path={`${match.url}redeemSuccess`}
          component={asyncComponent(() =>
            import('./Redeem/pages/RedeemSuccess')
          )}
        />

        <Route
          path={`${match.url}userProfile`}
          component={asyncComponent(() => import('./UserProfile/pages'))}
        />

        <Route
          path={`${match.url}account`}
          component={asyncComponent(() => import('./Account'))}
        />

        <Route
          path={`${match.url}chat`}
          component={asyncComponent(() => import('./Chat'))}
        />

        <Route
          path={`${match.url}todo`}
          component={asyncComponent(() => import('./Todo'))}
        />

        <Route
          path={`${match.url}pages/:page`}
          component={asyncComponent(() => import('./Page'))}
        />

        <Route component={asyncComponent(() => import('./NotFound'))} />
      </Switch>
    </Container>
  );
};

App.propTypes = {
  match: PropTypes.object.isRequired,
};

export default App;
