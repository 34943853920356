import {
  SET_DENOUNCE_SUCCESS,
  CHANGE_DENOUNCE_SUCCESS_STATE,
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  success: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DENOUNCE_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case CHANGE_DENOUNCE_SUCCESS_STATE: {
      return {
        ...state,
        success: false,
      };
    }
    default:
      return state;
  }
};
