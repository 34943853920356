import React from 'react';
import { Avatar } from 'antd';

const ReceivedMessageCell = ({ conversation, user }) => {
  let moment = require('moment')

  const getDateFormated = (dia) => {
    return moment(dia).format('HH:ss')
  }
  return (
    <div className="gx-chat-item">
      <Avatar
        className="gx-size-40 gx-align-self-end"
        src={user.thumb}
        alt=""
      />

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">{conversation.body}</div>
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
            {getDateFormated(conversation.sentAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivedMessageCell;
