import React from 'react';
import UserCell from './UserCellForTests/index';

const ChatUserList = ({ widget, chatUsers, selectedSectionId, onSelectUser }) => {
  return (
    <div className={`gx-chat-user ${widget ? 'widget' : null}`}>
      {chatUsers.map((chat, index) => (
        <UserCell
          key={index}
          chat={chat}
          selectedSectionId={selectedSectionId}
          onSelectUser={onSelectUser}
        />
      ))}
    </div>
  );
};

export default ChatUserList;
