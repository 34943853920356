export const getToken = () => localStorage.getItem('token');

export const login = token => {
  localStorage.setItem('token', token);
};

export const setUser = user => {
  localStorage.setItem('user', user);
};

export const getUser = () => {
  return (
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user')).data
  );
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};
