import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  fetchCollectionSessionSucess,
  fetchCollectionsSuccess,
  setCollectionSession as fetchCollectionSessionUpdate,
  fetchCollections,
} from '../actions/Collection';
import {
  GET_ALL_COLLECTIONS,
  SET_ITEMS_COLLECTIONS,
  SET_COLLECTION_POSITION,
  SET_COLLECTION_ITEM_POSITION,
  SET_COLLECTION_SESSION,
  REMOVE_ITEM_COLLECTIONS,
  REMOVE_COLLECTION,
  UPDATE_COLLECTION,
  SET_PRIVATE_COLLECTION,
  SET_COLLECTION,
} from '../../constants/ActionTypes';
import api from '../../services/api';

const getAllCollectionsRequest = async () => {
  try {
    const response = await api.get('collection');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const setCollectionRequest = async payload => {
  const { name, description } = payload;
  try {
    await api.post('collection', { name, description });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setCollectionSessionRequest = async payload => {
  const { id } = payload;
  try {
    const response = await api.get(`collection/${id}`);
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setCollectionPositionRequest = async payload => {
  try {
    await api.post('collection/order', {
      ids: payload,
    });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setCollectionItemPositionRequest = async payload => {
  const { idCollection, idArrays } = payload;
  try {
    await api.post(`collection/order/${idCollection}`, {
      ids: idArrays,
    });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setItemsCollectionsRequest = async payload => {
  const { className, item, collections } = payload;
  try {
    await api.post('collection/relation-item', {
      className,
      items: [item],
      collections: [collections],
    });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setPrivateCollectionsRequest = async payload => {
  const { id } = payload;
  try {
    await api.post(`collection/private/${id}`);
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const updateCollectionRequest = async payload => {
  const { id, name, description } = payload;
  try {
    await api.put(`collection/${id}`, { name, description });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const removeItemCollectionsRequest = async payload => {
  const { className, item, collections } = payload;
  try {
    await api.post('collection/remove-relation-item', {
      className,
      items: [item],
      collections: [collections],
    });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const removeCollectionRequest = async payload => {
  try {
    await api.delete(`collection/${payload}`);
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

function* setCollection({ payload }) {
  yield call(setCollectionRequest, payload);
  yield put(fetchCollections());
}

function* setCollectionSession({ payload }) {
  const collectionSession = yield call(setCollectionSessionRequest, payload);
  if (collectionSession)
    yield put(
      fetchCollectionSessionSucess({
        collectionSession,
        privateCollection: collectionSession.private,
      })
    );
}

function* setCollectionItemPosition({ payload }) {
  yield call(setCollectionItemPositionRequest, payload);
}

function* setCollectionPosition({ payload }) {
  yield call(setCollectionPositionRequest, payload);
}

function* setItemsCollections({ payload }) {
  yield call(setItemsCollectionsRequest, payload);
}

function* setPrivateCollections({ payload }) {
  const { id } = payload;
  yield call(setPrivateCollectionsRequest, payload);
  yield put(fetchCollectionSessionUpdate(id, false));
}

function* updateCollection({ payload }) {
  yield call(updateCollectionRequest, payload);
}

function* removeItemCollections({ payload }) {
  yield call(removeItemCollectionsRequest, payload);
}

function* removeCollection({ payload }) {
  yield call(removeCollectionRequest, payload);
  yield put(push('/collections'));
}

function* getAllCollections() {
  const collections = yield call(getAllCollectionsRequest);
  if (collections) yield put(fetchCollectionsSuccess(collections));
}

export function* fetchAllCollections() {
  yield takeEvery(GET_ALL_COLLECTIONS, getAllCollections);
}

export function* fetchCollection() {
  yield takeEvery(SET_COLLECTION, setCollection);
}

export function* fetchItemsCollections() {
  yield takeEvery(SET_ITEMS_COLLECTIONS, setItemsCollections);
}

export function* fetchPrivateCollections() {
  yield takeEvery(SET_PRIVATE_COLLECTION, setPrivateCollections);
}

export function* fetchUpdateCollection() {
  yield takeEvery(UPDATE_COLLECTION, updateCollection);
}

export function* fetchRemoveItemCollections() {
  yield takeEvery(REMOVE_ITEM_COLLECTIONS, removeItemCollections);
}

export function* fetchRemoveCollection() {
  yield takeEvery(REMOVE_COLLECTION, removeCollection);
}

export function* fetchCollectionSession() {
  yield takeEvery(SET_COLLECTION_SESSION, setCollectionSession);
}

export function* fetchCollectionItemPosition() {
  yield takeEvery(SET_COLLECTION_ITEM_POSITION, setCollectionItemPosition);
}

export function* fetchCollectionPosition() {
  yield takeEvery(SET_COLLECTION_POSITION, setCollectionPosition);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCollection),
    fork(fetchAllCollections),
    fork(fetchItemsCollections),
    fork(fetchCollectionSession),
    fork(fetchUpdateCollection),
    fork(fetchRemoveCollection),
    fork(fetchPrivateCollections),
    fork(fetchCollectionPosition),
    fork(fetchCollectionItemPosition),
    fork(fetchRemoveItemCollections),
  ]);
}
