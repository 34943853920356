import {
  GET_BLOG,
  FETCH_DYNAMIC_BLOG_SUCCESS,
  SELECT_CATEGORY,
} from '../../constants/ActionTypes';

export const onGetBlog = slug => {
  return {
    type: GET_BLOG,
    payload: slug,
  };
};

export const onSelectCategory = value => {
  return {
    type: SELECT_CATEGORY,
    payload: value,
  };
};

export const fetchDynamicBlogSuccess = (blogList, length) => {
  return {
    type: FETCH_DYNAMIC_BLOG_SUCCESS,
    payload: blogList,
    length,
  };
};
