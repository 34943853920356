import React, { useState, useEffect } from 'react';
import { Layout, Popover, Button } from 'antd';
import { MdApps } from 'react-icons/md';
import { AiFillDollarCircle, AiOutlineMenu } from 'react-icons/ai';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { statusData } from '../../routes/Gamification/data/gamificationData';

import Status from '../../routes/Gamification/components/Cards/Status';
import { onGetMenuShortcuts } from 'appRedux/actions/Menu';
import UserProgress from 'componentsCustom/UserProgress';
import AppNotification from 'components/AppNotification';
import MailNotification from 'components/MailNotification';
import Auxiliary from 'util/Auxiliary';
import CartHeader from '../../componentsCustom/eCommerce/CartHeader';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import { toggleCollapsedSideNav } from '../../appRedux/actions/Setting';

import { isMobile } from '../../config/consts';

const { Header } = Layout;

const HeaderContainer = styled.div`
  .dollar-icon {
    font-size: 1.3em;
  }

  .search-bottom-bar,
  .search-input {
    border: none;
    background: transparent;
    color: #fff;

    &:focus {
      outline: none;
    }
  }

  .points-container {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
      width: 90%;
      margin: 6px 0 14px;
    }
  }

  .points {
    display: flex;
    color: #fff;
    background: #038fde;
    padding: 5px 7px;
    border-radius: 20px;
    margin-right: 10px;
  }

  i,
  .shortcuts,
  .points {
    transition: 0.2s ease-in-out;
    &:hover {
      font-size: 1.3em;
      transition: 0.2s ease-in-out;
    }
  }

  .header-layout {
    padding: 0 10px;
    height: 50px;
    background: #2d3e50;

    .hamburger-icon {
      color: #fff;
    }

    .logo-empresa {
      height: 25px;
    }
  }

  .search-icon {
    color: #fff;
    font-size: 1.2em;
  }

  .search-bottom-bar,
  .search-bar {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-left: 15px;
    padding: 5px;
    display: flex;
    align-items: center;

    span {
      color: #fff;
    }

    .ant-input {
      border: none;
      opacity: 1;
    }
  }

  .right-search {
    display: none;
  }

  @media (max-width: 718px) {
    .search-bar {
      display: none;
    }
    .search-icon {
      display: none;
    }
    .right-search {
      display: block;
    }
    .tablet {
      display: none;
    }
  }

  @media (max-width: 530px) {
    .logo-empresa {
      display: none;
    }
  }
`;

const Topbar = () => {
  const [searchBottomBar, setSearchBottomBar] = useState(false);
  const dispatch = useDispatch();
  const { width, navCollapsed, navStyle } = useSelector(
    ({ settings }) => settings
  );
  const { shortcutsList } = useSelector(({ dynamicMenu }) => dynamicMenu);

  useEffect(() => {
    dispatch(onGetMenuShortcuts());
  }, []);

  return (
    <HeaderContainer>
      <Header className="header-layout">
        {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
          <div className="hamburger-icon gx-linebar gx-mr-3">
            <AiOutlineMenu
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </div>
        ) : null}
        <Link to="/dashboard">
          <img
            alt="Logo empresa"
            className="logo-empresa"
            src={require('assets/images/logo_horizontal2.png')}
          />
        </Link>

        <div className="search-bar">
          <SearchOutlined className="search-icon" />
          <input className="search-input" disabled={window.innerWidth < 719} />
        </div>

        <ul className="gx-header-notifications gx-ml-auto">
          <SearchOutlined
            className="search-icon right-search"
            onClick={() => setSearchBottomBar(!searchBottomBar)}
          />

          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={<AtalhosComponent shortcutsList={shortcutsList} />}
            trigger="click"
          >
            <div
              style={{ margin: '0 10px' }}
              className={`shortcuts gx-pointer ${
                window.innerWidth < 719 ? '' : 'search-bar'
              }`}
            >
              <MdApps style={{ color: '#fff', fontSize: '1.5em' }} />
              <p style={{ color: '#fff', margin: '0 5px' }} className="tablet">
                Atalhos
              </p>
            </div>
          </Popover>

          <Popover
            placement="bottomRight"
            content={
              <div
                className="points-container"
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Status autoHeight={true} dataFromApi={statusData} />
                <Button
                  style={{ width: '95%', margin: '15px 0 0' }}
                  className="btn primary"
                >
                  Ver detalhes
                </Button>
              </div>
            }
            trigger="click"
          >
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              className="points gx-pointer"
            >
              <p style={{ margin: '1px 2px 0 0' }}>277</p>
              <AiFillDollarCircle className="dollar-icon" />
            </div>
          </Popover>

          <Auxiliary>
            <li style={{ color: '#fff' }} className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<AppNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-notification" />
                </span>
              </Popover>
            </li>

            <li style={{ color: '#fff' }} className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<MailNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="icon icon-chat-new" />
                </span>
              </Popover>
            </li>

            <li style={{ color: '#fff' }} className="gx-msg">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<CartHeader />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="icon icon-shopping-cart" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Popover>
            </li>
          </Auxiliary>

          {isMobile ? (
            <UserProgress popover topbar mobile percent={87} />
          ) : (
            <UserProgress popover mobile autoHeight topbar percent={87} />
          )}
        </ul>
      </Header>
      {searchBottomBar && (
        <div
          style={{
            background: '#3c5166',
            padding: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input className="search-bottom-bar" type="text" />
          <div
            style={{
              marginLeft: '10px',
              color: '#fff',
              background: '#038fde',
              padding: '5px 10px',
              borderRadius: '5px',
            }}
          >
            Buscar
          </div>
        </div>
      )}
    </HeaderContainer>
  );
};

export const AtalhosComponent = ({ shortcutsList }) => {
  const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    width: 360px;
    list-style: none;
    justify-content: flex-start;
    padding: 0;

    li {
      margin: 0 10px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;

      a {
        border-radius: 5px;
        padding: 10px 0;
        width: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #545454;
        transition: 0.1s ease-in-out;

        i {
          font-size: 24px;
        }

        p {
          margin: 10px 0 0;
          font-size: 0.9em;
        }

        &:hover {
          box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.3);
          transition: 0.1s ease-in-out;
        }
      }
    }
  `;

  return (
    <List>
      {shortcutsList.map(main =>
        main.items.map(item => (
          <li key={item.id}>
            <a href={item.value} className="card-style">
              <i className={item.menu_icon}></i>
              <p>{item.name.en}</p>
            </a>
          </li>
        ))
      )}
    </List>
  );
};

export default Topbar;
