/* eslint-disable import/no-extraneous-dependencies */
import createSagaMiddleware, { END } from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import rootSaga from '../sagas/index';
import createRootReducer from '../reducers';
import asyncDispatchMiddleware from './asyncDispatch';

const { createBrowserHistory } = require('history');

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [
  thunk,
  sagaMiddleware,
  routeMiddleware,
  asyncDispatchMiddleware,
];

middlewares.push(logger);

export default function configureStore(preloadedState, dispatch) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(applyMiddleware(routerMiddleware(history), ...middlewares))
  );

  store.close = () => dispatch(END);

  sagaMiddleware.run(rootSaga);
  return store;
}
