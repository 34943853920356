/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getLearningPathsSuccess, getSingleLearningPathSuccess, setProgressOfCustomStepSuccess } from '../actions/LearningPath';
import {
  GET_LEARNING_PATHS,
  GET_SINGLE_LEARNING_PATH,
  SET_PROGRESS_OF_CUSTOM_STEP,
} from '../../constants/ActionTypes';
import api from '../../services/api';

const getLearningPathsRequest = async () => {
  try {
    const { data } = await api.get('learning-path');
    return data.data;
  } catch (error) {
    console.log('error -->', error);
  }
};

function* getLearningPaths() {
  const learningPaths = yield call(getLearningPathsRequest);
  if (learningPaths) yield put(getLearningPathsSuccess(learningPaths));
}

export function* fetchLearningPaths() {
  yield takeEvery(GET_LEARNING_PATHS, getLearningPaths);
}

const getSingleLearningPathRequest = async (payload) => {
  try {
    const { data } = await api.get(`learning-path/${payload}`);
    return data.data;
  } catch (error) {
    console.log('error -->', error);
  }
};

function* getSingleLearningPath({ payload }) {
  const singleLearningPath = yield call(getSingleLearningPathRequest, payload);
  if (singleLearningPath) yield put(getSingleLearningPathSuccess(singleLearningPath));
}

export function* fetchSingleLearningPath() {
  yield takeEvery(GET_SINGLE_LEARNING_PATH, getSingleLearningPath);
}

const setProgressOfCustomStepRequest = async (payload) => {
  try {
    const { learningPathSlug, stepId } = payload;
    const { data } = await api.post(`learning-path-step/complete/${learningPathSlug}/${stepId}`);
    return data;
  } catch (error) {
    console.log('error -->', error);
  }
};

function* setProgressOfCustomStep({ payload }) {
  const { success } = yield call(setProgressOfCustomStepRequest, payload);
  if (success) yield put(setProgressOfCustomStepSuccess(success));
}

export function* setProgressOfCustomStepInit() {
  yield takeEvery(SET_PROGRESS_OF_CUSTOM_STEP, setProgressOfCustomStep);
}

export default function* rootSaga() {
  yield all([
    fork(fetchLearningPaths),
    fork(fetchSingleLearningPath),
    fork(setProgressOfCustomStepInit),
  ]);
}
