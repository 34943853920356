import {
  FETCH_ALL_COURSES_SUCCESS,
  FETCH_SEARCH_LESSONS_MODULES_SUCCESS,
  FETCH_COURSE_SUCCESS,
  GET_ALL_COURSES,
  GET_COURSE,
  UPDATE_PERCENTAGE,
  SET_SEARCH_LESSONS_MODULES,
  GET_COURSE_REVIEWS,
  GET_COURSE_REVIEWS_SUCCESS,
} from '../../constants/ActionTypes';

export const fetchCourses = () => {
  return {
    type: GET_ALL_COURSES,
  };
};

export const fetchCourse = params => ({
  type: GET_COURSE,
  payload: params,
});

export const fetchCourseReviews = courseId => ({
  type: GET_COURSE_REVIEWS,
  payload: courseId,
});

export const fetchCourseReviewsSuccess = courseReviews => ({
  type: GET_COURSE_REVIEWS_SUCCESS,
  payload: courseReviews,
});

export const fetchSearchLessonsModules = paramsFilter => ({
  type: SET_SEARCH_LESSONS_MODULES,
  payload: paramsFilter,
});

export const updatePercentage = (moduleId, courseLessons, modules) => ({
  type: UPDATE_PERCENTAGE,
  payload: { moduleId, courseLessons, modules },
});

export const fetchCourseSuccess = course => ({
  type: FETCH_COURSE_SUCCESS,
  payload: course,
});

export const fetchCoursesSuccess = courseList => ({
  type: FETCH_ALL_COURSES_SUCCESS,
  payload: courseList,
});

export const fetchSearchLessonsModulesSuccess = resourcesFilter => ({
  type: FETCH_SEARCH_LESSONS_MODULES_SUCCESS,
  payload: resourcesFilter,
});
