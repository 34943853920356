import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Form, Input, notification } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux';

import {
  showAuthLoader,
  resetPassword,
  hideMessage
} from 'appRedux/actions/Auth';
import logo from '../assets/images/logo_horizontal.png';

const FormItem = Form.Item;

const ResetPassword = ({ match, form, confirmDirty, authUser, showAuthLoader, resetPassword, alertMessage, showMessage, hideMessage, history }) => {
  const { token } = match.params;

  useEffect(() => {
    if (authUser !== null) {
      history.push('/');
    }
  }, [authUser, history])

  const openNotification = () => {
    const args = {
      message: 'Reset password',
      description: alertMessage.charAt(0).toUpperCase() + alertMessage.slice(1),
      duration: 5,
    };
    notification.open(args);
  };

  useEffect(() => {
    if (showMessage) {
      openNotification();
      hideMessage();
    }
    // eslint-disable-next-line
  }, [showMessage, hideMessage]);

  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        showAuthLoader();
        resetPassword(values, token);

        await new Promise(r => setTimeout(r, 5000));
        history.push('/login');
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback("Your two passwords don't match.");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  const { getFieldDecorator } = form;

  return (
    <div className="gx-login-container">
      <div
        className="gx-login-content"
        style={{ padding: '10px 35px 20px', position: 'absolute' }}
      >
        <div
          className="gx-login-header gx-text-center"
          style={{ marginBottom: 0 }}
        >
          <img src={logo} alt="" style={{ padding: '30px 0' }} />
        </div>
        <div className="gx-mb-4">
          <h2>
            <IntlMessages id="app.userAuth.resetPassword" />
          </h2>
          <p>
            <IntlMessages id="appModule.enterPasswordReset" />
          </p>
        </div>

        <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
          <FormItem>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'This is not a valid password.',
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            })(
              <Input style={{ height: '50px' }}
                type="password"
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="New password"
                size="large" />
            )}
          </FormItem>

          <FormItem>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Confirm your password.',
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            })(
              <Input style={{ height: '50px' }}
                type="password"
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Retype new password"
                size="large"
              />
            )}
          </FormItem>

          <FormItem className="gx-text-center">
            <Button type="primary" htmlType="submit" block size="large">
              <IntlMessages id="app.userAuth.reset" />
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

ResetPassword.defaultProps = {
  authUser: null,
  confirmDirty: undefined,
}

ResetPassword.propTypes = {
  authUser: PropTypes.any,
  confirmDirty: PropTypes.any,
  form: PropTypes.any.isRequired,
  match: PropTypes.object.isRequired,
  showAuthLoader: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  alertMessage: PropTypes.string.isRequired,
  showMessage: PropTypes.any.isRequired,
  hideMessage: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
};

const WrappedResetPasswordForm = Form.create()(ResetPassword);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  showAuthLoader,
  resetPassword,
  hideMessage
})(WrappedResetPasswordForm);
