export const itens = [
  {
    id: 1,
    image:
      'https://res.cloudinary.com/hilnmyskv/image/fetch/h_300,q_100,f_auto/http://www.ikea.com/us/en/images/products/legitim-chopping-board-white__0119427_PE275744_S4.JPG',
    name: 'Legitim Chopping board, white',
    message: 'There are many variations of passages of...',
    price: '241,82',
    badge: 1,
  },
  {
    id: 2,
    image:
      'https://res.cloudinary.com/hilnmyskv/image/fetch/h_300,q_100,f_auto/http://www.ikea.com/us/en/images/products/potatischips-graddfil-lok-sour-cream-and-onion-potato-chips__79438_PE203428_S4.JPG',
    name: 'John Smith',
    message: 'Lorem Ipsum is simply dummy text of the...',
    price: '10,82',
    badge: 3,
  },
  {
    id: 3,
    image:
      'https://res.cloudinary.com/hilnmyskv/image/fetch/h_300,q_100,f_auto/http://www.ikea.com/us/en/images/products/cirkustalt-children-s-tent__0120516_PE277185_S4.JPG',
    name: 'John Smith',
    price: '1.150,45',
    message: 'The point of using Lorem Ipsum is that it has a...',
    badge: 8,
  },
];
