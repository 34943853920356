import {
  GET_ALL_COLLECTIONS,
  SET_COLLECTION,
  SET_COLLECTION_TYPE,
  SET_PRIVATE_COLLECTION,
  SET_COLLECTION_ITEM_POSITION,
  SET_COLLECTION_POSITION,
  SET_ITEMS_COLLECTIONS,
  SET_COLLECTION_SESSION,
  UPDATE_COLLECTION,
  REMOVE_ITEM_COLLECTIONS,
  REMOVE_COLLECTION,
  FETCH_ALL_COLLECTIONS_SUCCESS,
  FETCH_COLLECTION_SESSION_SUCCESS,
} from '../../constants/ActionTypes';

export const fetchCollections = loader => {
  return {
    type: GET_ALL_COLLECTIONS,
    payload: loader,
  };
};

export const setCollection = (name, description) => {
  return {
    type: SET_COLLECTION,
    payload: { name, description },
  };
};

export const setCollectionSession = (id, loader) => {
  return {
    type: SET_COLLECTION_SESSION,
    payload: { id, loader },
  };
};

export const setCollectionType = (type, id) => {
  return {
    type: SET_COLLECTION_TYPE,
    payload: { type, id },
  };
};

export const setPrivateCollection = (id, privateCollection) => {
  return {
    type: SET_PRIVATE_COLLECTION,
    payload: { id, privateCollection },
  };
};

export const setCollectionItemPosition = (idCollection, idArrays) => {
  return {
    type: SET_COLLECTION_ITEM_POSITION,
    payload: { idCollection, idArrays },
  };
};

export const setCollectionPosition = idArrays => {
  return {
    type: SET_COLLECTION_POSITION,
    payload: idArrays,
  };
};

export const setItemCollections = (className, item, collections) => {
  return {
    type: SET_ITEMS_COLLECTIONS,
    payload: { className, item, collections },
  };
};

export const updateCollection = (id, name, description, collection) => {
  return {
    type: UPDATE_COLLECTION,
    payload: { id, name, description, collection },
  };
};

export const removeItemCollections = (className, item, collections) => {
  return {
    type: REMOVE_ITEM_COLLECTIONS,
    payload: { className, item, collections },
  };
};

export const removeCollection = id => {
  return {
    type: REMOVE_COLLECTION,
    payload: id,
  };
};

export const fetchCollectionsSuccess = collectionsList => ({
  type: FETCH_ALL_COLLECTIONS_SUCCESS,
  payload: collectionsList,
});

export const fetchCollectionSessionSucess = collectionSession => ({
  type: FETCH_COLLECTION_SESSION_SUCCESS,
  payload: collectionSession,
});
