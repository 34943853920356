import React from 'react';
import { Avatar } from 'antd';

const SentMessageCell = ({ conversation }) => {
  let moment = require('moment')

  const getDateFormated = (dia) => {
    return moment(dia).format('HH:ss')
  }
  return (
    <div className="gx-chat-item gx-flex-row-reverse">
      <Avatar
        className="gx-size-40 gx-align-self-end"
        src="https://via.placeholder.com/150x150"
        alt={conversation.name}
      />

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">{conversation.body}</div>
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
            {getDateFormated(conversation.created_at)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentMessageCell;
