import {
    LeftOutlined,
    MinusOutlined,
    CloseOutlined,
    CameraOutlined,
    SendOutlined,
    WechatOutlined,
    AudioOutlined
} from '@ant-design/icons'
import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    right: 25px;
    bottom: -500px;
    width: 355px;
    height: 456px;
    z-index:100;
    background: #FFFFFF;
    box-shadow: 1px 5px 20px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
    transition: all .7s ease-out;

    &.show{
        position: fixed;
        bottom: 0px;
    }

    @media (max-height: 436px) {
        height: calc(100% - 20px);
    }

    @media (max-width: 380px) {
        width: 100%;
        height: 100%;
        right: 0px;
        border-radius: 0px;
    }

    .gx-status-pos {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 80px;
        padding-left: 10px;
        padding-right: 2px;
        

        .gx-status.gx-online{
            position:relative;
            margin-left: -12px;
            margin-right: 10px;
            margin-bottom: 5px;
            
        }
    }
`;



export const TitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100px;
`;
export const Title = styled.p`
    font-family: 'NoirPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin:0px!important;
    letter-spacing: -0.606157px;
    color: #000000;
`;

export const TitleUser = styled.p`
    font-family: 'NoirPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin:0px!important;
    letter-spacing: -0.606157px;
    color: #000000;
`;

export const ButtonChat = styled.h4`
    font-family: 'NoirPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    margin:0px!important;
    letter-spacing: -0.606157px;
    color: #575757;
`;

export const Flex = styled.div`
    display: flex;

    .widget{
        width: 100%;

        .gx-chat-user-item {
            padding: 16px 16px 16px 0px;
        }
    }

    &.overflow{
        overflow-y: scroll;
        height: 436px;
        border-bottom: 1px solid #DAD9E2;
        
        @media (max-height: 486px) {
            height: calc(100% - 100px);
        }
    }

    &.flex1{
        flex:1;
    }
    &.flex-end{
        justify-content:flex-end;
    }
    &.flex-start{
        justify-content:flex-start;
    }
    &.align-center{
        align-items:center;
    }
    &.justify-content-center{
        justify-content:center;
    }
    &.margin{
        margin-right: 20px;
    }

`

export const OverFlow = styled.div`
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);
    position: absolute;
    top:70;
    left:0;
    border-bottom: 1px solid #DAD9E2;

    ::-webkit-scrollbar {
        width: 0px;
    }

`

export const Padding = styled.div`
    padding: 15px;

    &.Send{
        padding: 11px 0px;
    }
`
export const Footer = styled.div`
    position: absolute;
    width: 355px;
    height: 46px;
    left: calc(50% - 355px/2);
    bottom: 0px;
    background: #ECECEC;
    display:flex;
    justify-content:center;
    align-items:center;

    a {
        text-align:center;
        font-family: 'NoirPro', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.606157px;
        color: #2D47D2;
    }
`

export const Header = styled.div`
    width: 100%;
    height: 64px;
    background-image: linear-gradient(to bottom,  #eaeaea,  #FFFFFF);
    padding: 10px;
    border-radius: 10px 10px 10px 10px;
`

export const SpaceBetween = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`
export const Left = styled(LeftOutlined)`
    font-size:20px;
    margin-right:20px;
    cursor:pointer;
    border-radius: 50%;
    transition: 0.5s;
    padding: 10px;
    &:hover{
       background-color: rgb(0,0,0,0.1)
    }
`
export const Minus = styled(MinusOutlined)`
    font-size:20px;
    margin-right:20px;
    cursor:pointer;
    border-radius: 50%;
    transition: 0.5s;
    padding: 10px;
    &:hover{
       background-color: rgb(0,0,0,0.1)
    }
`
export const Close = styled(CloseOutlined)`
    font-size:20px;
    cursor:pointer;
    border-radius: 50%;
    transition: 0.5s;
    padding: 10px;
    &:hover{
       background-color: rgb(0,0,0,0.1)
    }
`
export const Camera = styled(CameraOutlined)`
    font-size:26px;
    margin-right:10px;
    margin-left:10px;
    cursor:pointer;
`
export const Audio = styled(AudioOutlined)`
    font-size:26px;
    margin-right:20px;
    cursor:pointer;
`
export const Send = styled(SendOutlined)`
    font-size:26px;
    margin-right:20px;
    cursor:pointer;
`

export const WeChat = styled(WechatOutlined)`
    color: white;
    font-size: 36px;
`

export const WeChatContainer = styled.div`
    position: absolute;
    cursor: pointer;
    bottom: 53px;
    right: 15px;
    background: #52c31a;
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 8px;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    z-index:100;

    &:hover{
        transform: scale(1.1);
        box-shadow: 1px 1px 4px #cccccc;
    }
`