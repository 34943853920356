import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;

  .ant-card-body {
    height: ${({ autoHeight }) => (autoHeight ? 'auto' : '450px')};
    margin: 0;
  }

  .ant-progress-circle {
    margin: 0;
  }

  .ant-card {
    margin-bottom: 0 !important;
  }

  .gx-widget-badge {
    background: #f8b101;
    padding: 5px 8px;
  }

  .points {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    right: 0;

    p {
      margin: 0;
      font-size: 1.1em;
      color: #fff;
    }
  }

  .user-container {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    text-align: center;
    margin: 10px 0 3px 0;
  }

  .fa-award {
    font-size: 3em;
    background: #f8b101;
    color: #b27c14;
    padding: 10px;
    border-radius: 50px;
  }

  .fa-coins {
    font-size: 1.7em;
    margin-left: 3px;
    color: #fff;
  }

  .default-text {
    font-size: 0.8em;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .text {
    font-size: 0.8em;
    margin-left: 10px;
    text-align: left;

    p {
      margin-bottom: 0px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`;

export const InsigniasContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .fa-star {
    font-size: 2em;
    margin: 5px 3px 0 3px;
    color: #fff;
    background: #0474da;
    padding: 7px;
    border-radius: 50px;
  }

  .bw {
    background: gray;
  }
`;
