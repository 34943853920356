import {
  FETCH_PRODUCT_SUCCESS,
  FETCH_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS,
  GET_PRODUCT,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  productSession: null,
  productsList: [],
  loader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_ALL_PRODUCTS: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_PRODUCT_SUCCESS: {
      return {
        ...state,
        loader: false,
        productSession: action.payload,
      };
    }

    case FETCH_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        loader: false,
        productsList: action.payload,
      };

    default:
      return state;
  }
};
