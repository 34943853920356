import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_ALL_PRODUCTS } from '../../constants/ActionTypes';

import api from '../../services/api';
import { fetchProductsSuccess } from '../actions';

const getAllProductsRequest = async () => {
  try {
    const response = await api.get(`product`);
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getAllProducts() {
  const products = yield call(getAllProductsRequest);
  if (products.data) yield put(fetchProductsSuccess(products.data));
}

export function* fetchAllProducts() {
  yield takeEvery(GET_ALL_PRODUCTS, getAllProducts);
}

export default function* rootSaga() {
  yield all([fork(fetchAllProducts)]);
}
