/* eslint-disable no-useless-escape */
const createSlug = string => {
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';

  const withoutLocaleChar = string
    .split('')
    .map((letter, i) =>
      letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    );

  return withoutLocaleChar
    .toString()
    .toLowerCase()
    .trim()
    .replace(' ', '') // Removing spaces
    .replace(/[^\w\-]+/g, '') // Removing remaining non word
    .replace(/\-\-+/g, '-'); // Removing ocasional repeated "-"
};

export default createSlug;
