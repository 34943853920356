import {
  FETCH_COMMENTS_SUCCESS,
  GET_COMMENTS,
  REMOVE_COMMENT,
  SET_COMMENT,
  SET_COMMENT_SUCCESS,
  SET_COMMENT_SUCCESS_API,
  SET_COMMENT_ID_REPLY,
  SET_COMMENT_LIKE,
  SET_COMMENT_LIKE_SUCCESS,
  UPDATE_COMMENT_SUCCESS,
  REMOVE_COMMENT_SUCCESS,
} from 'constants/ActionTypes';

const INIT_STATE = {
  commentIdReply: null,
  commentsList: [],
  comment: null,
  params: {},
  totalComments: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMENTS: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case SET_COMMENT_LIKE: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case SET_COMMENT_LIKE_SUCCESS: {
      return {
        ...state,
      };
    }

    case SET_COMMENT_ID_REPLY: {
      return {
        ...state,
        commentIdReply: action.payload,
      };
    }

    case SET_COMMENT: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case SET_COMMENT_SUCCESS: {
      return {
        ...state,
        commentsList: Array.from(action.payload),
      };
    }

    case SET_COMMENT_SUCCESS_API: {
      return {
        ...state,
        commentsList: Array.from(action.payload),
      };
    }

    case UPDATE_COMMENT_SUCCESS: {
      return {
        ...state,
        commentsList: Array.from(action.payload),
      };
    }

    case REMOVE_COMMENT: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case REMOVE_COMMENT_SUCCESS: {
      return {
        ...state,
        commentsList: Array.from(action.payload),
      };
    }

    case FETCH_COMMENTS_SUCCESS: {
      return {
        ...state,
        commentsList: action.payload.comments,
        totalComments: action.payload.totalComments,
      };
    }

    default:
      return state;
  }
};
