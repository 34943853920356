import {
  STYLES_CONTENT_COURSE,
  STYLES_EDIT_COMMENT,
} from '../../constants/ActionTypes';

export const setStyleContentCourse = styles => {
  return {
    type: STYLES_CONTENT_COURSE,
    payload: styles,
  };
};

export const setStyleEditComment = (editComment, id) => {
  return {
    type: STYLES_EDIT_COMMENT,
    payload: { editComment, id },
  };
};
