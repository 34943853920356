import {
  GET_ALL_FORUMS,
  GET_ALL_TOPICS_FORUM,
  GET_SECTION_FORUM,
  FETCH_ALL_FORUMS_SUCCESS,
  FETCH_SECTION_FORUM_SUCCESS,
  FETCH_ALL_TOPICS_FORUM_SUCCESS,
  GET_ONE_FORUM,
  FETCH_ONE_FORUM_SUCCESS,
  ROUTE_FROM_FORUM_CATEGORIES,
  SET_TOPIC,
} from '../../constants/ActionTypes';

export const onGetRouteFromForumCategories = params => {
  return {
    type: ROUTE_FROM_FORUM_CATEGORIES,
    payload: params,
  };
};

export const onSetTopic = (
  title,
  description,
  tags,
  slugFromTitle,
  slugCategoryForum,
  slugSectionForum
) => {
  return {
    type: SET_TOPIC,
    payload: {
      title,
      description,
      tags,
      slugFromTitle,
      slugCategoryForum,
      slugSectionForum,
    },
  };
};

export const onGetOneForum = params => {
  return {
    type: GET_ONE_FORUM,
    payload: params,
  };
};

export const onGetAllForums = () => {
  return {
    type: GET_ALL_FORUMS,
  };
};

export const onGetAllTopicsForum = (slugCategory, slugSectionForum, page) => {
  return {
    type: GET_ALL_TOPICS_FORUM,
    payload: { slugCategory, slugSectionForum, page },
  };
};

export const onGetSectionForum = (slugCategory, slugSectionForum) => {
  return {
    type: GET_SECTION_FORUM,
    payload: { slugCategory, slugSectionForum },
  };
};

export const fetchOneForumSuccess = (forum, route) => {
  return {
    type: FETCH_ONE_FORUM_SUCCESS,
    payload: forum,
    route,
  };
};

export const fetchAllForumsSuccess = forumsInCategories => {
  return {
    type: FETCH_ALL_FORUMS_SUCCESS,
    payload: forumsInCategories,
  };
};

export const fetchSectionForumSuccess = section => {
  return {
    type: FETCH_SECTION_FORUM_SUCCESS,
    payload: section,
  };
};

export const fetchTopicsForumSuccess = topics => {
  return {
    type: FETCH_ALL_TOPICS_FORUM_SUCCESS,
    payload: topics,
  };
};
