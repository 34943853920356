import {
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  FETCH_CHAT_CONTACTS_SUCCESS,
  FETCH_CONVERSATION_SUCCESS,
  SEND_MESSAGE_SUCCESS,
  CLEAR_CONVERSATION,
  SHOW_CHAT_ERROR,
  ADD_MESSAGE_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  contacts: [],
  conversation: [],
  currentConversationUser: null,
  lastSendedMenssage: null,
  error: false,
  mesageError: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CHAT_CONTACTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        contacts: action.payload,
      };
    }
    case FETCH_CONVERSATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        conversation: action.payload.conversation,
        currentConversationUser: action.payload.currentConversationUser
      };
    }
    case CLEAR_CONVERSATION: {
      return {
        ...state,
        conversation: [],
        currentConversationUser: null,
        lastSendedMenssage: null
      };
    }
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        loader: false,
        conversation: [...state.conversation, action.payload],
      };
    }
    case ADD_MESSAGE_SUCCESS: {
      return {
        ...state,
        conversation: [...state.conversation, action.payload],
      };
    }
    case SHOW_CHAT_ERROR: {
      return {
        ...state,
        loader: false,
        error: true,
        mesageError: action.payload,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
