import { SET_DENOUNCE, SET_DENOUNCE_SUCCESS, CHANGE_DENOUNCE_SUCCESS_STATE } from '../../constants/ActionTypes';

export const setDenounce = (userId, reportedUserId, type, description) => {
  return {
    type: SET_DENOUNCE,
    payload: { userId, reportedUserId, type, description },
  };
};

export const setDenounceSuccess = () => {
  return {
    type: SET_DENOUNCE_SUCCESS,
  };
};

export const changeSuccessState = () => {
  return {
    type: CHANGE_DENOUNCE_SUCCESS_STATE,
  };
}
