import {
  FETCH_ALL_COLLECTIONS_SUCCESS,
  SET_COLLECTION,
  SET_COLLECTION_TYPE,
  UPDATE_COLLECTION,
  SET_COLLECTION_SESSION,
  SET_PRIVATE_COLLECTION,
  FETCH_COLLECTION_SESSION_SUCCESS,
  GET_ALL_COLLECTIONS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  collectionsList: [],
  collectionSession: null,
  loader: true,
  loaderSession: true,
  collectionType: '',
  privateCollection: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_COLLECTIONS: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case SET_COLLECTION: {
      return {
        ...state,
        loader: true,
      };
    }

    case UPDATE_COLLECTION: {
      return {
        ...state,
        collectionSession: action.payload.collection,
      };
    }

    case SET_COLLECTION_SESSION: {
      return {
        ...state,
        loaderSession: action.payload.loader,
      };
    }

    case SET_PRIVATE_COLLECTION: {
      return {
        ...state,
        privateCollection: action.payload.privateCollection,
      };
    }

    case SET_COLLECTION_TYPE: {
      return {
        ...state,
        collectionType: action.payload,
      };
    }

    case FETCH_ALL_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        collectionsList: action.payload,
        loader: false,
      };
    }

    case FETCH_COLLECTION_SESSION_SUCCESS: {
      return {
        ...state,
        loaderSession: false,
        collectionSession: action.payload.collectionSession,
        privateCollection: action.payload.privateCollection,
      };
    }

    default:
      return state;
  }
};
