import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  fetchCoursesSuccess,
  fetchCourseSuccess,
  fetchSearchLessonsModulesSuccess,
  fetchCourseReviewsSuccess,
} from '../actions/Course';
import {
  GET_ALL_COURSES,
  GET_COURSE,
  SET_SEARCH_LESSONS_MODULES,
  GET_COURSE_REVIEWS,
} from '../../constants/ActionTypes';
import api from '../../services/api';

const getAllCoursesRequest = async () => {
  try {
    const response = await api.get('course');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getCourseRequest = async slug => {
  try {
    const response = await api.get(`course/${slug}`);
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setSearchLessonsModulesRequest = async slug => {
  try {
    const response = await api.get(
      `course/${slug}/search?search=course&resources%5B0%5D=modules&resources%5B1%5D=lessons`
    );

    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const getCourseReviewsRequest = async courseId => {
  const contentable_type = 'Vico068\\CourseTools\\Course';

  try {
    const response = await api.get(`review/?contentable_type=${contentable_type}&contentable_id=${courseId}`);
    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

function* getCourseReviews({ payload }) {
  const courseReviews = yield call(getCourseReviewsRequest, payload);
  if (courseReviews) yield put(fetchCourseReviewsSuccess(courseReviews));
}

function* getCourse({ payload }) {
  const course = yield call(getCourseRequest, payload);
  if (course) yield put(fetchCourseSuccess(course));
}

function* setSearchLessonsModules({ payload }) {
  const resourcesFilter = yield call(setSearchLessonsModulesRequest, payload);
  if (resourcesFilter)
    yield put(fetchSearchLessonsModulesSuccess(resourcesFilter));
}

function* getAllCourses() {
  const courses = yield call(getAllCoursesRequest);
  if (courses) yield put(fetchCoursesSuccess(courses));
}

export function* fetchAllCourses() {
  yield takeEvery(GET_ALL_COURSES, getAllCourses);
}

export function* fetchCourseReviews() {
  yield takeEvery(GET_COURSE_REVIEWS, getCourseReviews);
}

export function* fetchSingleCourse() {
  yield takeEvery(GET_COURSE, getCourse);
}

export function* fetchSearchLessonsModules() {
  yield takeEvery(SET_SEARCH_LESSONS_MODULES, setSearchLessonsModules);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAllCourses),
    fork(fetchSingleCourse),
    fork(fetchSearchLessonsModules),
    fork(fetchCourseReviews),
  ]);
}
