import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const UserCell = ({ chat, selectedSectionId, onSelectUser }) => {
  return (
    <div
      className={`gx-chat-user-item ${
        selectedSectionId === chat.id ? 'active' : ''
        }`}
      onClick={() => {
        onSelectUser(chat);
      }}
    >
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          <div className="gx-status-pos">
            {chat.avatar_url ?
              <Avatar src={chat.avatar_url} className="gx-size-60" alt={chat.name} />
              :
              <Avatar size="large" icon={<UserOutlined />} className="gx-size-60" alt="Abbott" />
            }
            <span className={`gx-status gx-small gx-kh`} />
          </div>
        </div>

        <div className="gx-chat-info">
          <span className="gx-name h4">{chat.name}</span>
          <div className="gx-chat-info-des gx-text-truncate">
            {`ultima mensagem enviada...`}
          </div>
          <div className="gx-last-message-time">00:00</div>
        </div>

        <div className="gx-chat-date">
          <div className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white">
            9
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCell;
