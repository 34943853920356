import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import blogSagas from './Blog';
import courseSagas from './Courses';
import commentSagas from './Comments';
import collectionSagas from './Collections';
import denounceSagas from './Denounce';
import moduleSagas from './Modules';
import lessonSagas from './Lessons';
import notesSagas from './Notes';
import productSagas from './Product';
import userSagas from './User';
import dynamicMenu from './Menu';
import todoSagas from './Todo';
import forumSagas from './Forum';
import learningPathSagas from './LearningPaths';
import pageSagas from './Page';
import chatSagas from './Chat';
import customScriptSagas from './CustomScripts';

export default function* rootSaga() {
  yield all([
    authSagas(),
    blogSagas(),
    courseSagas(),
    commentSagas(),
    collectionSagas(),
    denounceSagas(),
    dynamicMenu(),
    moduleSagas(),
    lessonSagas(),
    notesSagas(),
    productSagas(),
    userSagas(),
    todoSagas(),
    forumSagas(),
    learningPathSagas(),
    pageSagas(),
    chatSagas(),
    customScriptSagas(),
  ]);
}
