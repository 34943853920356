import {
  FETCH_USER_SUCCESS,
  FETCH_DEVICES_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_INTERESTS_SUCCESS,
  GET_INTERESTS,
  GET_USER,
  GET_USER_DEVICES,
  GET_USER_COUNTRIES,
  SET_PAGE,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_AUTHORS_SUCCESS,
  GET_ALL_NON_AUTHOR_USERS_SUCCESS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  user: {},
  interests: null,
  devices: null,
  countries: null,
  users: null,
  authors: null,
  nonAuthors: null,
  page: 'about',
  slugId: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    case GET_USER: {
      return {
        ...state,
        user: action.payload,
        // slugId: action.payload,
        // loading: true,
      };
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    }

    case GET_USER_DEVICES: {
      return {
        ...state,
        devices: action.payload,
      };
    }

    case FETCH_DEVICES_SUCCESS: {
      return {
        ...state,
        devices: action.payload,
      };
    }

    case GET_INTERESTS: {
      return {
        ...state,
        interests: action.payload,
      };
    }

    case FETCH_INTERESTS_SUCCESS: {
      return {
        ...state,
        interests: action.payload,
      };
    }

    case GET_USER_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_ALL_AUTHORS_SUCCESS: {
      return {
        ...state,
        authors: action.payload,
      };
    }

    case GET_ALL_NON_AUTHOR_USERS_SUCCESS: {
      return {
        ...state,
        nonAuthors: action.payload,
      };
    }

    default:
      return state;
  }
};
