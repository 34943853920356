import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser } from 'services/auth';

import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from 'appRedux/actions/Setting';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from '../../constants/ThemeSetting';

const Container = styled.div`
  height: 50px;

  .gx-linebar {
    top: 5px;
  }

  .gx-icon-btn:hover {
    background-color: #1f2b38;
  }

  .username {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    p {
      color: #fff;
      font-size: 1.3em;
      margin: 0 0 0 10px;
    }
  }
`;

const SidebarLogo = () => {
  const dispatch = useDispatch();
  const user = getUser();
  const firstNameLocalStorage = user && user.first_name;
  const { width, themeType, navCollapsed } = useSelector(
    ({ settings }) => settings
  );
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <Container className="gx-layout-sider-header">
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon icon-${
              navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'
            } ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
            onClick={() => {
              if (navStyle === NAV_STYLE_DRAWER) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else if (navStyle === NAV_STYLE_FIXED) {
                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
              } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else {
                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
              }
            }}
          />
        </div>
      ) : null}

      <Link to="/" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR &&
        width >= TAB_SIZE ? null : (
          <span className="username">
            <p>Olá, {firstNameLocalStorage}!</p>
          </span>
        )}
      </Link>
    </Container>
  );
};

export default SidebarLogo;
