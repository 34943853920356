export const statusData = {
  image: 'https://randomuser.me/api/portraits/med/women/33.jpg',
  credits: 243,
  nextLevel: 123,
  percent: 15,
  level: 'Dama',
  points: 4421,
  ranking: 73,
  name: 'Santana Molina',
  data: [
    { name: 'Page A', price: 200 },
    { name: 'Page B', price: 800 },
    { name: 'Page C', price: 1600 },
    { name: 'Page D', price: 2200 },
    { name: 'Page D', price: 1400 },
    { name: 'Page H', price: 2960 },
    { name: 'Page K', price: 1960 },
  ],
};

export const newsData = [
  {
    color: 'red',
    title: 'Novo curso! "Mágicas com cartas"',
    date: '01/04/2020',
  },
  {
    color: 'green',
    title: 'Aula 21 com vídeo corrigido',
    date: '01/04/2020',
  },
  {
    title: 'Aplicativo mobile com novo recurso',
    date: '01/04/2020',
  },
  {
    color: 'red',
    title: 'Novo curso! "Mágicas com cartas"',
    date: '01/04/2020',
  },
  {
    color: 'green',
    title: 'Aula 21 com vídeo corrigido',
    date: '01/04/2020',
  },
  {
    title: 'Aplicativo mobile com novo recurso',
    date: '01/04/2020',
  },
  {
    color: 'red',
    title: 'Novo curso! "Mágicas com cartas"',
    date: '01/04/2020',
  },
  {
    color: 'green',
    title: 'Aula 21 com vídeo corrigido',
    date: '01/04/2020',
  },
  {
    title: 'Aplicativo mobile com novo recurso',
    date: '01/04/2020',
  },
];

export const progressData = [
  {
    percent: 75,
    title: 'Cursos Platinum',
    completedCourses: '12',
    hoursWatched: '314',
    unattendedCourses: '8',
  },
  {
    percent: 75,
    title: 'Área VIP',
    completedCourses: '72',
    hoursWatched: '2142',
    unattendedCourses: '34',
  },
  {
    percent: 75,
    title: 'Loja',
    completedCourses: '72',
    hoursWatched: '2142',
    unattendedCourses: '34',
  },
];

export const areaVipData = [
  {
    text: 'Aparição dos 4 Ases',
    comments: 29,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 59,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 31,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 76,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 9,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 88,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 5,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 4,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 16,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
  {
    text: 'Aparição dos 4 Ases',
    comments: 78,
    url: 'https://guilhermeavila.com.br/files/imagem_padrao_video.png',
  },
];

export const cursosPlatinumData = [
  {
    percent: 29,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 59,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 31,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 76,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 9,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 88,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 5,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 4,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 16,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
  {
    percent: 78,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/73c61f91860763.5e3c5889b80f4.jpg',
  },
];

export const blogData = [
  {
    text: 'Blog da Ensinio',
    comments: 29,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/dbb08191860763.5e3c5889bb7b6.jpg',
  },
  {
    text: 'Blog da Ensinio',
    comments: 59,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/dbb08191860763.5e3c5889bb7b6.jpg',
  },
];

export const continueAssistindoData = [
  {
    text: 'Continue assistindo',
    percent: 29,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 59,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 31,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 76,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 9,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 88,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 5,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 4,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 16,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
  {
    text: 'Continue assistindo',
    percent: 78,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4895a91860763.5e3c5889b878c.jpg',
  },
];

export const produtosDeMagicaData = [
  {
    text: 'Loja da Ensinio',
    comments: 29,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 59,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 31,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 76,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 9,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 88,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 5,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 4,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 16,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
  {
    text: 'Loja da Ensinio',
    comments: 78,
    url:
      'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d2aeae91860763.5e3c5889bd05c.jpg',
  },
];

export const professoresData = [
  {
    id: 1,
    personType: 'Author',
    level: 'Houdini',
    areaVip: 77,
    cursosPlatinum: 33,
    name: 'Petersen Parsons',
    percent: 56,
    points: 4386,
    type: '',
    image: 'https://randomuser.me/api/portraits/med/men/15.jpg',
  },
  {
    id: 2,
    personType: 'Author',
    level: 'Houdini',
    areaVip: 88,
    cursosPlatinum: 89,
    name: 'Jordan Valencia',
    percent: 6,
    points: 4465,
    type: '',
    image: 'https://randomuser.me/api/portraits/med/men/94.jpg',
  },
  {
    id: 3,
    personType: 'Author',
    level: 'Houdini',
    areaVip: 60,
    cursosPlatinum: 90,
    name: 'John Bauer',
    percent: 60,
    points: 4863,
    type: '',
    image: 'https://randomuser.me/api/portraits/med/men/1.jpg',
  },
  {
    id: 4,
    personType: 'Author',
    level: 'Houdini',
    areaVip: 90,
    cursosPlatinum: 6,
    name: 'Carlos Navarro',
    percent: 47,
    points: 2405,
    type: '',
    image: 'https://randomuser.me/api/portraits/med/men/72.jpg',
  },
  {
    id: 5,
    personType: 'Author',
    level: 'Houdini',
    areaVip: 11,
    cursosPlatinum: 22,
    name: 'Hahn Miranda',
    percent: 39,
    points: 2589,
    type: '',
    image: 'https://randomuser.me/api/portraits/med/men/41.jpg',
  },
  {
    id: 6,
    personType: 'Author',
    level: 'Houdini',
    areaVip: 33,
    cursosPlatinum: 93,
    name: 'Matt Brady',
    percent: 87,
    points: 2939,
    type: '',
    image: 'https://randomuser.me/api/portraits/med/men/88.jpg',
  },
  {
    id: 7,
    personType: 'Author',
    level: 'Houdini',
    areaVip: 71,
    cursosPlatinum: 17,
    name: 'Tom Richards',
    percent: 71,
    points: 3004,
    type: '',
    image: 'https://randomuser.me/api/portraits/med/men/86.jpg',
  },
  {
    id: 8,
    personType: 'Author',
    level: 'Houdini',
    areaVip: 46,
    cursosPlatinum: 87,
    name: 'Saul Mayer',
    percent: 68,
    points: 1995,
    type: '',
    image: 'https://randomuser.me/api/portraits/med/men/1.jpg',
  },
];
