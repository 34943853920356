import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { connect } from 'react-redux';

import {
  showAuthLoader,
  hideMessage,
  confirmEmail,
} from 'appRedux/actions/Auth';
import logo from '../assets/images/logo_horizontal.png';

const ConfirmEmail = ({ match, confirmEmail, authUser, alertMessage, showMessage, hideMessage, history }) => {
  const { token } = match.params;

  useEffect(() => {
    if (authUser !== null) {
      history.push('/');
    }
  }, [authUser, history])

  const redirectToLogin = async () => {
    await new Promise(r => setTimeout(r, 5000));
    history.push('/login');
  }

  useEffect(() => {
    if (token) {
      confirmEmail(token);

      redirectToLogin();
    }
    // eslint-disable-next-line
  }, [token, confirmEmail]);

  const openNotification = () => {
    const args = {
      message: 'Email confirmation',
      description: alertMessage.charAt(0).toUpperCase() + alertMessage.slice(1),
      duration: 5,
    };
    notification.open(args);
  };

  useEffect(() => {
    if (showMessage) {
      openNotification();
      hideMessage();
    }
    // eslint-disable-next-line
  }, [showMessage, hideMessage]);

  return (
    <div className="gx-login-container">
      <div
        className="gx-login-content"
        style={{ padding: '10px 35px 20px', position: 'absolute' }}
      >
        <div
          className="gx-login-header gx-text-center"
          style={{ marginBottom: 0 }}
        >
          <img src={logo} alt="" style={{ padding: '30px 0' }} />
        </div>
        { token ? (
          <div className="gx-mb-4">
            <h2>
              Checking email confirmation token
            </h2>
            <hr />
            <p>
              When the check is over, you will be redirected.
            </p>
            <br />
            <p>
              Thank you for joining us :)
            </p>
          </div>
        ) : (
          <div className="gx-mb-4">
            <h2>
              Email confirmation
            </h2>
            <hr />
            <p>
              You must confirm your email to complete the registration.
            </p>
            <br />
            <p>
              Just click on the link we sent you :)
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

ConfirmEmail.defaultProps = {
  authUser: null,
}

ConfirmEmail.propTypes = {
  authUser: PropTypes.any,
  match: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func.isRequired,
  alertMessage: PropTypes.string.isRequired,
  showMessage: PropTypes.any.isRequired,
  hideMessage: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  showAuthLoader,
  confirmEmail,
  hideMessage
})(ConfirmEmail);
