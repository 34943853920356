import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  fetchModuleSuccess,
  fetchModuleSessionSuccess,
} from '../actions/Module';
import { GET_MODULES, GET_MODULE_SESSION } from '../../constants/ActionTypes';
import api from '../../services/api';

const getModulesRequest = async courseSlug => {
  try {
    const response = await api.get(`course/${courseSlug}/module`);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getModuleSessionRequest = async payload => {
  const { courseSlug, moduleSlug } = payload;
  try {
    const response = await api.get(`course/${courseSlug}/${moduleSlug}`);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getModules({ payload }) {
  const modules = yield call(getModulesRequest, payload);
  if (modules) yield put(fetchModuleSuccess(modules));
}

function* getModuleSession({ payload }) {
  const moduleSession = yield call(getModuleSessionRequest, payload);
  if (moduleSession) yield put(fetchModuleSessionSuccess(moduleSession));
}

export function* fetchModules() {
  yield takeEvery(GET_MODULES, getModules);
}

export function* fetchModuleSession() {
  yield takeEvery(GET_MODULE_SESSION, getModuleSession);
}

export default function* rootSaga() {
  yield all([fork(fetchModules), fork(fetchModuleSession)]);
}
