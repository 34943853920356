/* eslint-disable no-console */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchNotes, fetchNoteSuccess, setNoteSuccess } from '../actions/Notes';

import {
  GET_NOTES,
  REMOVE_NOTE,
  SET_NOTE,
  UPDATE_NOTE,
} from '../../constants/ActionTypes';

import api from '../../services/api';

const updateNoteRequest = async payload => {
  try {
    const { lessonSlug, description, id, time } = payload;
    const response = await api.put(`notes/${lessonSlug}/${id}`, {
      description,
      the_second: time,
    });
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
};

const removeNoteRequest = async payload => {
  try {
    const { lessonSlug, id } = payload;
    const response = await api.delete(`notes/${lessonSlug}/${id}`);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getLessonNotesRequest = async payload => {
  try {
    const { lessonSlug } = payload;
    const response = await api.get(`/notes/${lessonSlug}`);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const noteRequest = async payload => {
  try {
    const { lessonSlug, theSecond, Description } = payload;
    const response = await api.post(`notes/${lessonSlug}`, {
      the_second: Math.round(theSecond),
      description: Description,
    });
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* removeNote({ payload }) {
  const { lessonSlug } = payload;
  yield call(removeNoteRequest, payload);
  yield put(fetchNotes(lessonSlug));
}

function* updateNote({ payload }) {
  const { lessonSlug } = payload;
  const noteChanged = yield call(updateNoteRequest, payload);

  if (noteChanged) {
    yield put(fetchNotes(lessonSlug));
  }
}

function* getLessonNotes({ payload }) {
  const bookmarkers = yield call(getLessonNotesRequest, payload);
  if (bookmarkers) yield put(fetchNoteSuccess(bookmarkers));
}

function* setNote({ payload }) {
  const markerResponse = yield call(noteRequest, payload);
  const { theSecond, Description, lessonSlug } = payload;

  const payloadMarker = {
    time: Math.round(theSecond),
    description: Description,
    id: markerResponse.id,
  };

  if (markerResponse) {
    yield put(setNoteSuccess(payloadMarker));
    yield put(fetchNotes(lessonSlug));
  }
}

export function* setMarkers() {
  yield takeEvery(SET_NOTE, setNote);
}

export function* fetchLessonBookMarkers() {
  yield takeEvery(GET_NOTES, getLessonNotes);
}

export function* removeBookmarkers() {
  yield takeEvery(REMOVE_NOTE, removeNote);
}

export function* updateBookmarkers() {
  yield takeEvery(UPDATE_NOTE, updateNote);
}

export default function* rootSaga() {
  yield all([
    fork(fetchLessonBookMarkers),
    fork(setMarkers),
    fork(removeBookmarkers),
    fork(updateBookmarkers),
  ]);
}
