import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchDynamicBlogSuccess } from '../actions/Blog';
import { GET_BLOG } from '../../constants/ActionTypes';
import api from '../../services/api';

const getDynamicBlogRequest = async payload => {
  try {
    if (!payload) {
      const response = await api.get('blog');
      return response.data.data;
    }
    const response = await api.get(payload);
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

function* getDynamicBlog({ payload }) {
  if (payload) {
    const { length } = payload.split('/').filter(item => item !== '');
    const dynamicBlog = yield call(getDynamicBlogRequest, payload);
    if (dynamicBlog) yield put(fetchDynamicBlogSuccess(dynamicBlog, length));
  }
  const dynamicBlog = yield call(getDynamicBlogRequest);
  if (dynamicBlog) yield put(fetchDynamicBlogSuccess(dynamicBlog));
}

export function* fetchDynamicBlog() {
  yield takeEvery(GET_BLOG, getDynamicBlog);
}

export default function* rootSaga() {
  yield all([fork(fetchDynamicBlog)]);
}
