/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getPageSuccess } from '../actions/Page';
import {
  GET_PAGE,
} from '../../constants/ActionTypes';
import api from '../../services/api';

const getPageRequest = async (payload) => {
  try {
    const { data } = await api.get(`page/${payload}`);
    return data.data;
  } catch (error) {
    console.log('error -->', error);
  }
};

function* getPage({ payload }) {
  const pageData = yield call(getPageRequest, payload);
  yield put(getPageSuccess(pageData));
}

export function* fetchPage() {
  yield takeEvery(GET_PAGE, getPage);
}

export default function* rootSaga() {
  yield all([
    fork(fetchPage),
  ]);
}
