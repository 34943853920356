import {
  GET_TODO,
  FETCH_DYNAMIC_TODO_SUCCESS,
} from '../../constants/ActionTypes';

export const onGetTodo = () => {
  return {
    type: GET_TODO,
  };
};

export const fetchDynamicTodoSuccess = todoList => {
  return {
    type: FETCH_DYNAMIC_TODO_SUCCESS,
    payload: todoList,
  };
};
