import {
  GET_LEARNING_PATHS,
  GET_LEARNING_PATHS_SUCCESS,
  GET_SINGLE_LEARNING_PATH,
  GET_SINGLE_LEARNING_PATH_SUCCESS,
  SET_PROGRESS_OF_CUSTOM_STEP,
  SET_PROGRESS_OF_CUSTOM_STEP_SUCCESS,
} from '../../constants/ActionTypes';

export const getLearningPaths = () => ({
  type: GET_LEARNING_PATHS,
});

export const getLearningPathsSuccess = learningPaths => ({
  type: GET_LEARNING_PATHS_SUCCESS,
  payload: learningPaths,
});

export const getSingleLearningPath = learningPathSlug => ({
  type: GET_SINGLE_LEARNING_PATH,
  payload: learningPathSlug,
});

export const getSingleLearningPathSuccess = singleLearningPath => ({
  type: GET_SINGLE_LEARNING_PATH_SUCCESS,
  payload: singleLearningPath,
});

export const setProgressOfCustomStep = (learningPathSlug, stepId) => ({
  type: SET_PROGRESS_OF_CUSTOM_STEP,
  payload: {
    learningPathSlug,
    stepId
  },
});

export const setProgressOfCustomStepSuccess = percentageHasUpdated => ({
  type: SET_PROGRESS_OF_CUSTOM_STEP_SUCCESS,
  payload: percentageHasUpdated,
});
