/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CONFIRM_USER_EMAIL,
} from 'constants/ActionTypes';
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from '../../firebase/firebase';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
} from '../actions/Auth';

import { getToken, login, logout, setUser } from '../../services/auth';
import api from '../../services/api';
import createSlug from '../../util/createSlug';

const createUserWithEmailPasswordRequest = async payload => {
  const response = await api.post('register', {
    ...payload,
  });

  setUser(JSON.stringify(response.data));
  login(response.data.token);

  api.interceptors.request.use(async config => {
    const token = getToken();

    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  return response.data;
};

const signInUserWithEmailPasswordRequest = async payload => {
  const { email, password } = payload;

  const response = await api.post('login', {
    email,
    password,
  });

  setUser(JSON.stringify(response.data));

  login(response.data.token);

  api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  return response.data;
};

const forgotPasswordRequest = async payload => {
  const { email } = payload;

  const response = await api.post('user/password/reset', {
    email,
  });

  api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  return response.data;
};

const resetPasswordRequest = async ({ formValues, token }) => {
  const { password } = formValues;

  const response = await api.post('user/password/newpassword', {
    password,
    token,
  });

  api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  return response.data;
};

const confirmEmailRequest = async ({ token }) => {
  const response = await api.post('user/confirm', {
    token,
  });

  api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  return response.data;
};

const signOutRequest = async () => logout();

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  const { name, email, password, confirmPassword } = payload;

  if (password !== confirmPassword) {
    yield put(showAuthMessage('Mismatched passwords.'));
    return;
  }

  const splitName = name.split(' ');
  const [first_name] = splitName;
  const last_name = splitName.length > 1 ? splitName.reverse()[0] : '';

  const username = createSlug(name) + Math.floor(Math.random() * 100);
  const display_name = `${first_name}${last_name ? ' ' : ''}${last_name}`;

  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, {
      email,
      password,
      first_name,
      last_name,
      display_name,
      username,
    });

    if (signUpUser && signUpUser.data) {
      yield put(userSignUpSuccess(email));
    }
  } catch (error) {
    const firstArrayOfMessages = error.response.data[Object.keys(error.response.data)[0]];
    const message = firstArrayOfMessages[0];
    yield put(showAuthMessage(message || "Something went wrong."));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.data.message));
    } else {
      localStorage.setItem('user_id', signInUser.data.id);
      yield put(userSignInSuccess(signInUser.data.id));
    }
  } catch (error) {
    const { error: err_message } = error.response.data;
    yield put(showAuthMessage(err_message));
  }
}

function* resetNewPassword({ payload }) {
  try {
    const resetPass = yield call(resetPasswordRequest, payload);

    if (resetPass && resetPass.msg) {
      yield put(showAuthMessage(resetPass.msg));
    }
  } catch (error) {
    if (error.response.data && error.response.data.success === false) {
      yield put(showAuthMessage(error.response.data.msg));
    } else {
      const { error: err_message } = error.response.data;
      yield put(showAuthMessage(err_message));
    }
  }
}

function* forgotMyPassword({ payload }) {
  try {
    const resetPass = yield call(forgotPasswordRequest, payload);

    if (resetPass && resetPass.msg) {
      yield put(showAuthMessage(resetPass.msg, resetPass.success));
    }
  } catch (error) {
    if (error.response.data && error.response.data.success === false) {
      yield put(showAuthMessage(error.response.data.msg));
    } else {
      const { error: err_message } = error.response.data;
      yield put(showAuthMessage(err_message));
    }
  }
}

function* onConfirmEmail({ payload }) {
  try {
    const response = yield call(confirmEmailRequest, payload);

    if (response && response.msg) {
      yield put(showAuthMessage(response.msg));
    }
  } catch (error) {
    if (error.response.data && error.response.data.success === false) {
      yield put(showAuthMessage(error.response.data.msg));
    } else {
      const { error: err_message } = error.response.data;
      yield put(showAuthMessage(err_message));
    }
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (!signOutUser) {
      localStorage.removeItem('user_id');
      yield put(userSignOutSuccess(signOutUser));
      yield put(push('/login'));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* forgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotMyPassword);
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, resetNewPassword);
}

export function* confirmEmail() {
  yield takeEvery(CONFIRM_USER_EMAIL, onConfirmEmail);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(forgotPassword),
    fork(resetPassword),
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
  ]);
}
