import {
  FETCH_USER_SUCCESS,
  FETCH_DEVICES_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_INTERESTS_SUCCESS,
  SET_PAGE,
  GET_INTERESTS,
  GET_USER,
  GET_USER_COUNTRIES,
  GET_USER_DEVICES,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_AUTHORS,
  GET_ALL_AUTHORS_SUCCESS,
  GET_ALL_NON_AUTHOR_USERS,
  GET_ALL_NON_AUTHOR_USERS_SUCCESS,
  FOLLOW_USER,
  FOLLOW_USER_SUCCESS,
  UNFOLLOW_USER,
  UNFOLLOW_USER_SUCCESS,
} from '../../constants/ActionTypes';

export const onGetUser = () => ({
  type: GET_USER,
});

export const fetchOneUser = user => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});

export const getAllInterests = () => ({
  type: GET_INTERESTS,
});

export const fetchAllInterests = interests => ({
  type: FETCH_INTERESTS_SUCCESS,
  payload: interests,
});

export const getAllCountries = () => ({
  type: GET_USER_COUNTRIES,
});

export const fetchAllCountries = countries => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload: countries,
});

export const onGetUserDevices = () => ({
  type: GET_USER_DEVICES,
});

export const fetchAllDevices = devices => ({
  type: FETCH_DEVICES_SUCCESS,
  payload: devices,
});

export const setPage = page => ({
  type: SET_PAGE,
  payload: page,
});

export const getAllUsers = page => ({
  type: GET_ALL_USERS,
  payload: page,
});

export const getAllUsersSuccess = users => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: users,
});

export const getAllAuthors = page => ({
  type: GET_ALL_AUTHORS,
  payload: page,
});

export const getAllAuthorsSuccess = authors => ({
  type: GET_ALL_AUTHORS_SUCCESS,
  payload: authors,
});

export const getAllNonAuthorUsers = page => ({
  type: GET_ALL_NON_AUTHOR_USERS,
  payload: page,
});

export const getAllNonAuthorUsersSuccess = users => ({
  type: GET_ALL_NON_AUTHOR_USERS_SUCCESS,
  payload: users,
});

export const followUser = userId => ({
  type: FOLLOW_USER,
  payload: userId,
});

export const followUserSuccess = succeeded => ({
  type: FOLLOW_USER_SUCCESS,
  payload: succeeded,
});

export const unfollowUser = userId => ({
  type: UNFOLLOW_USER,
  payload: userId,
});

export const unfollowUserSuccess = succeeded => ({
  type: UNFOLLOW_USER_SUCCESS,
  payload: succeeded,
});
