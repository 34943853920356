import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';

import { fetchDynamicTodoSuccess } from '../actions/Todo';
import { GET_TODO } from '../../constants/ActionTypes';

const getDynamicTodoRequest = async () => {
  try {
    const response = await api.get('blog');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

function* getDynamicTodo() {
  const dynamicTodo = yield call(getDynamicTodoRequest);
  if (dynamicTodo) yield put(fetchDynamicTodoSuccess(dynamicTodo));
}

export function* fetchDynamicTodo() {
  yield takeEvery(GET_TODO, getDynamicTodo);
}

export default function* rootSaga() {
  yield all([fork(fetchDynamicTodo)]);
}
