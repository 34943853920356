import { FETCH_DYNAMIC_TODO_SUCCESS } from '../../constants/ActionTypes';

const INIT_STATE = {
  todoList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DYNAMIC_TODO_SUCCESS: {
      return {
        ...state,
        todoList: action.payload,
      };
    }

    default:
      return state;
  }
};
