import React, { useEffect } from 'react';
import { Menu, Badge } from 'antd';
import ScrollBar from 'react-perfect-scrollbar';

import styled from 'styled-components';

import CustomScrollbars from 'util/CustomScrollbars';
import { onGetMainMenu } from 'appRedux/actions/Menu';
import { useSelector, useDispatch } from 'react-redux';
import SidebarLogo from './SidebarLogo';
import { THEME_TYPE_LITE } from '../../constants/ThemeSetting';

const { SubMenu } = Menu;

const Content = styled.div`
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 85px) !important;
  }

  .badge-container {
    height: 16px;
    padding: 0 6px;
    margin-left: 8px;
    background: #038fde;
    border-radius: 25px;

    .badge {
      color: #fff;
      font-size: 0.7em;
      font-weight: 500;
      margin-top: -3px;
    }
  }

  i {
    margin-right: 10px !important;
    font-size: ${({ navStyle }) =>
      navStyle === 'NAV_STYLE_FIXED' ? '1em' : '1.3em'};
  }

  .ant-menu-submenu-title {
    height: 45px !important;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    height: 45px;

    a {
      color: #fff;
      display: flex;
      align-items: center;
    }
  }

  .ant-badge {
    margin: 0;
  }

  .ant-menu-submenu-active {
    color: yellowgreen !important;
  }

  .ant-menu-submenu-title {
    display: flex;
    color: #fff;
  }

  .gx-sidebar-notifications {
    height: 90px;
    border-bottom-color: #2d3e50;
  }

  .gx-sidebar-notifications {
    padding: 10px !important;
    height: auto;
  }
`;

const SidebarContent = () => {
  const dispatch = useDispatch();
  const { themeType, pathname, navStyle } = useSelector(
    ({ settings }) => settings
  );
  const menu = useSelector(({ dynamicMenu }) => dynamicMenu.mainMenuList);

  useEffect(() => {
    dispatch(onGetMainMenu());
  }, [dispatch]);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <Content navStyle={navStyle} className="gx-sidebar-content">
      <SidebarLogo />
      <div className="gx-sidebar-notifications" />
      <ScrollBar>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            {menu.length > 0 &&
              menu.map(item =>
                item.menu_type === 'menu'
                  ? item.items.map(subitem => {
                      if (subitem.children.length > 0) {
                        return (
                          <SubMenu
                            key={subitem.id}
                            title={
                              <span
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <i className={subitem.menu_icon} />
                                <BadgeBuilder
                                  navStyle={navStyle}
                                  items={subitem}
                                  charsNumber={5}
                                />
                              </span>
                            }
                          >
                            {subitem.children.map(item => {
                              if (item.children.length > 0) {
                                return (
                                  <SubMenu
                                    key={item.id}
                                    title={
                                      <span
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <i className={item.menu_icon} />

                                        <BadgeBuilder
                                          navStyle={navStyle}
                                          items={item}
                                          charsNumber={5}
                                        />
                                      </span>
                                    }
                                  >
                                    {item.children.map(item2 => {
                                      return (
                                        <Menu.Item key={item2.id}>
                                          <i className={item2.menu_icon} />
                                          <a
                                            href={item2.value}
                                            target={item2.target}
                                          >
                                            <BadgeBuilder
                                              navStyle={navStyle}
                                              items={item2}
                                              charsNumber={5}
                                            />
                                          </a>
                                        </Menu.Item>
                                      );
                                    })}
                                  </SubMenu>
                                );
                              }
                              return (
                                <Menu.Item key={item.id}>
                                  <i className={item.menu_icon} />
                                  <a href={item.value} target={item.target}>
                                    <BadgeBuilder
                                      navStyle={navStyle}
                                      items={item}
                                      charsNumber={5}
                                    />
                                  </a>
                                </Menu.Item>
                              );
                            })}
                          </SubMenu>
                        );
                      }
                      return (
                        <Menu.Item title={subitem.name.en} key={subitem.id}>
                          <i className={subitem.menu_icon} />
                          <a href={subitem.value} target={subitem.target}>
                            <BadgeBuilder
                              navStyle={navStyle}
                              items={subitem}
                              charsNumber={15}
                            />
                          </a>
                        </Menu.Item>
                      );
                    })
                  : null
              )}
          </Menu>
        </CustomScrollbars>
      </ScrollBar>
    </Content>
  );
};

const BadgeBuilder = ({ navStyle, items, charsNumber }) => {
  return navStyle === 'NAV_STYLE_MINI_SIDEBAR' ? null : (
    <>
      {items.name.en.length < charsNumber ? (
        <>
          {items.name.en}
          {items.use_badge && (
            <Badge
              count={items.badge_text.en}
              style={{
                boxShadow: 'none',
                background: '#038FDE',
                fontSize: '0.8em',
                marginLeft: '5px',
                borderRadius: '5px',
                padding: '0px 3px',
                height: 'auto',
                lineHeight: '16px',
              }}
            />
          )}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {items.name.en}
          {items.use_badge && (
            <Badge
              count={items.badge_text.en}
              style={{
                boxShadow: 'none',
                background: '#038FDE',
                fontSize: '0.8em',
                borderRadius: '5px',
                padding: '0px 3px',
                height: 'auto',
                lineHeight: '16px',
                top: '-10px',
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
