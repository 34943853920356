/* eslint-disable react/no-children-prop */
import React, { memo, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Avatar,
    Form,
} from 'antd';

import {
    Container,
    Title,
    Flex,
    Footer,
    Header,
    Padding,
    SpaceBetween,
    Left,
    Minus,
    Close,
    Camera,
    Audio,
    Send,
    WeChat,
    WeChatContainer,
    ButtonChat,
    OverFlow,
    TitleUser,
    TitleContainer
} from './styles';
import ChatInput from './../../routes/Chat/pages/ChatInput';
import ChatUserList from './../../components/chat/ChatUserList';
import Conversation from './../../components/chat/Conversation';
import conversationData from './../../routes/Chat/pages/conversa';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchContacts,
    fetchConversation,
    sendMessage
} from 'appRedux/actions/Chat';
import { UserOutlined } from '@ant-design/icons';


const Widget = memo(({ handleChat, handleAlert }) => {

    const dispatch = useDispatch();
    const chat = useSelector(state => state.chat)
    const auth = useSelector(state => state.auth)
    /*const allUsers = [
        { id: 1, name: "Amanda Ribeiro", status: "kh", lastMessage: "khj", lastMessageTime: "khjj", unreadMessage: 9 },
        { id: 2, name: "teste Ribeiro", status: "kh", lastMessage: "khj", lastMessageTime: "khjj", unreadMessage: 9 },
    ]*/
    const allUsers = chat.contacts
    const borderStyle = { background: 'rgba(255, 255, 255, 0.8)', border: '1px solid #E9E9E9', borderRadius: 10 };
    const boderSearch = { background: '#ECECEC', borderRadius: '7px' }
    const [routerChat, setRouterChat] = useState(true)
    const [user, setUser] = useState(allUsers[0]);
    const [chatUsers, setChatUsers] = useState(chat.contacts)
    const [textMenssage, setTextMenssage] = useState("")


    useEffect(() => {
        getContacts()
    }, [])

    const getContacts = () => {
        dispatch(fetchContacts())
    }

    const handleSend = () => {
        let msg = {
            contact_id: user.id,
            body: textMenssage
        }
        dispatch(sendMessage(msg))
        setTextMenssage("")
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            handleSend()
        }
    }

    const onSelectUser = (user) => {
        setUser(user)
        setRouterChat(false);
        dispatch(fetchConversation(user.id))
    }


    const onChangeInput = (e) => {
        const text = e.target.value.trim()
        if (text !== '') {
            const filter = chat.contacts.filter((el) => el.name.toUpperCase().indexOf(text.toUpperCase()) >= 0)
            setChatUsers(filter)
        } else {
            setChatUsers(allUsers);
        }
    }

    const handleClickClose = () => {
        document.querySelector("#container-chat").classList.toggle("show")
        setTimeout(() => {
            handleChat(true)
        }, 700);
    }

    return routerChat ?
        <Container id="container-chat">
            <Padding>
                <Flex style={{ height: '60px' }} className="align-center">
                    <div className="gx-status-pos">
                        <span className="gx-status gx-online"></span>
                        <Title>Chat</Title>
                    </div>
                    <ChatInput onChangeInput={onChangeInput} styles={boderSearch} styleContainer={{ flex: 1 }} placeholder="Search or start new chat"></ChatInput>
                    <div style={{ marginLeft: 10 }} >
                        <Close size={16} onClick={() => { handleClickClose(true) }} />
                    </div>
                </Flex>
                <Flex>
                    <OverFlow>
                        <ChatUserList onSelectUser={onSelectUser} widget={true} chatUsers={chat.contacts}></ChatUserList>
                    </OverFlow>
                </Flex>
                <Flex>
                    <Footer>
                        <Link to="/chat">
                            <ButtonChat>
                                Abrir página do Chat
                            </ButtonChat>
                        </Link>
                    </Footer>
                </Flex>
            </Padding>
        </Container>
        : <Container>
            <Flex>
                <Header>
                    <SpaceBetween>
                        <Flex className="align-center flex-start margin">
                            <Left onClick={() => { setRouterChat(true) }} />
                            {user.avatar_url ?
                                <Avatar src={user.avatar_url} className="gx-size-40" alt={user.name} />
                                :
                                <Avatar size="large" icon={<UserOutlined />} className="gx-size-40" alt="Abbott" />
                            }
                        </Flex>
                        <Flex className="align-center flex1 flex-start margin">
                            <TitleUser>{user.name}</TitleUser>
                        </Flex>
                        <Flex className="align-center flex1 flex-end">
                            <Minus onClick={() => { handleChat(true) }} />
                            <Close />
                        </Flex>
                    </SpaceBetween>
                </Header>
            </Flex>
            <Flex >
                <OverFlow>
                    <Padding >
                        <Conversation conversationData={chat.conversation} userId={auth.authUser}></Conversation>
                    </Padding>
                </OverFlow>
            </Flex>
            <Flex className="justify-content-center align-center" style={{ padding: '15px 10px' }}>
                <Footer>
                    {textMenssage.trim() == "" && <Camera onClick={() => handleAlert(true)}></Camera>}
                    <ChatInput onEnterPress={(e)=>onKeyDown(e)} value={textMenssage} onChangeInput={e => setTextMenssage(e.target.value)} styleContainer={{ width: '100%', paddingRight: '10px' }} placeholder="" styles={borderStyle}></ChatInput>
                    {textMenssage.trim() == "" && <Audio onClick={() => handleAlert(true)}></Audio>}
                    {textMenssage.trim() != "" && <Send onClick={handleSend} />}
                </Footer>
            </Flex>
        </Container>

})
const ChatWidget = memo(({ }) => {
    const [minus, setMinus] = useState(true)
    const [show, setShow] = useState(true)
    const location = useLocation();
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        if (location.pathname === "/chat") {
            handleChat(true);
            setShow(false)
        }
    }, [location])

    const handleChat = (condition) => {
        setMinus(condition);
    }

    const handleClickAvatar = () => {
        handleChat(false)
        setTimeout(() => {
            document.querySelector("#container-chat").classList.toggle("show")
        }, 100);
    }


    return (<> {show ? minus ? <WeChatContainer onClick={() => { handleClickAvatar() }}>
        <WeChat></WeChat>
    </WeChatContainer>
        : <Widget handleAlert={(show) => setShowAlert(show)} handleChat={handleChat}></Widget>
        : <></>}
        <SweetAlert
            warning
            show={showAlert}
            onConfirm={() => setShowAlert(false)}
            title="Esta funcionalidade não está disponível no momento."
        >

        </SweetAlert>
    </>
    );
});

ChatWidget.defaultProps = {};

ChatWidget.propTypes = {};

const WrappedRegistrationForm = Form.create({ name: 'form_modal_denounce' })(
    ChatWidget
);

export default WrappedRegistrationForm;
