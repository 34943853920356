import React, { memo } from 'react';
import { Avatar, Button, Col } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { Container, ContentRow } from './styles';

const Item = memo(({ item }) => {
  const { image, price, badge, name, message } = item;
  return (
    <>
      <Container>
        <li className="gx-media">
          <div className="gx-user-thumb gx-mr-3">
            <Avatar className="gx-size-40" alt={image} src={image} />
            {badge > 0 ? (
              <span className="gx-badge gx-badge-danger gx-text-white gx-rounded-circle">
                {badge}
              </span>
            ) : null}
          </div>
          <div className="gx-media-body">
            <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
              <h5 className="gx-text-capitalize gx-user-name gx-mb-0">
                <span className="gx-link">{name}</span>
              </h5>
            </div>
            <p className="gx-fs-sm">{message}</p>

            <ContentRow>
              <Col span={15}>
                <span>R$ {price} for </span>{' '}
                <span style={{ fontWeight: 500 }}>{badge}</span>
              </Col>

              <Col span={4}>
                <Button
                  size="small"
                  type="primary"
                  shape="circle"
                  // onClick={() => setCurrentAmount(currentAmount + 1)}
                >
                  <PlusOutlined />
                </Button>
              </Col>

              <Col span={4}>
                <Button
                  size="small"
                  type="primary"
                  shape="circle"
                  // onClick={() => setCurrentAmount(currentAmount - 1)}
                >
                  <MinusOutlined />
                </Button>
              </Col>
            </ContentRow>
          </div>
        </li>
      </Container>
      <hr style={{ margin: '10px 0px 10px 0px' }} />
    </>
  );
});

Item.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Item;
