/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import styled from 'styled-components';
import { Progress, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { onGetProfileMenu } from 'appRedux/actions/Menu';
import dark from 'assets/images/icon_dark.svg';
import { userSignOut } from 'appRedux/actions';
import { UserProgressContainer, Container } from './styles';
import { getUser } from '../../services/auth';

const ContainerPopover = styled(Popover)`
  margin-top: 0;
  li {
    display: flex;
    align-items: center;

    .icon {
      font-size: 1.3em;
      margin-right: 10px;
    }
  }
`;

const List = styled.ul`
  padding: 5px;
  li {
    width: 100%;
    display: flex;
    align-items: center;

    .icon {
      font-size: 1.3em;
      margin-right: 10px;
    }
  }
`;

const UserProgress = ({
  image,
  autoHeight,
  topbar = false,
  zeroMarginTop,
  percent,
  mobile,
  mini = false,
  percentLabel,
  sizeDefault,
  overflowImage = false,
}) => {
  const dispatch = useDispatch();
  const user = getUser();
  const avatarUserLocalStorage = user && user.photo;
  const menu = useSelector(({ dynamicMenu }) => dynamicMenu.profileMenuList);
  const menuProfile = menu[0];

  const userMenuOptions = (
    <List style={{ width: 220 }} className="gx-user-popover">
      <li>
        <h4>Guilherme Ávila</h4>
      </li>
      <li>
        <i className="icon far fa-user-circle" />
        <Link style={{ color: '#545454' }} to="/userProfile">
          Perfil público
        </Link>
      </li>
      <li>
        <img
          style={{ marginRight: 10, width: 19 }}
          src={dark}
          alt="Dark icon"
        />
        Dark Mode
      </li>
      <li>
        <i className="icon fas fa-cog" />
        <Link style={{ color: '#545454' }} to="/account">
          Minha conta
        </Link>
      </li>
      <hr />
      {menuProfile &&
        menuProfile.items.map((item) => (
          <li key={item.id}>
            <div style={{ width: 30 }}>
              <i className={`icon ${item.menu_icon}`} />
            </div>
            <Link style={{ color: '#545454' }} to={item.value}>
              {item.name.en}
            </Link>
          </li>
        ))}
      <hr />
      <li>
        <i className="icon fas fa-globe" />
        Português
      </li>
      <li onClick={() => dispatch(userSignOut())}>
        <i className="icon fas fa-sign-out-alt" />
        <span onClick={() => dispatch(userSignOut())}>Sair</span>
      </li>
    </List>
  );

  return (
    <>
      {topbar ? (
        <div style={{ marginTop: 0 }}>
          <ContainerPopover
            overlayClassName="gx-popover-horizontal"
            placement="bottomRight"
            content={userMenuOptions}
            onClick={() => dispatch(onGetProfileMenu())}
            trigger="click"
          >
            <UserProgressContainer
              overflowImage={overflowImage}
              sizeDefault={sizeDefault}
              autoHeight={autoHeight}
              mobile={mobile}
              topbar={topbar}
            >
              {percentLabel && <p>{`${percentLabel}%`}</p>}
              <Container mini={mini} sizeDefault={sizeDefault} mobile={mobile}>
                <Progress
                  strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                  className="progress-bar"
                  type="circle"
                  percent={percent}
                />
                <img src={image || avatarUserLocalStorage} alt="" />
                <div className="white-bg" />
              </Container>
            </UserProgressContainer>
          </ContainerPopover>
        </div>
      ) : (
          <UserProgressContainer
            overflowImage={overflowImage}
            sizeDefault={sizeDefault}
            mobile={mobile}
            topbar={topbar}
            zeroMarginTop={zeroMarginTop}
          >
            {percentLabel && <p>{`${percentLabel}%`}</p>}
            <Container mini={mini} sizeDefault={sizeDefault} mobile={mobile}>
              <Progress
                strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                className="progress-bar"
                type="circle"
                percent={percent}
              />
              <img src={image || avatarUserLocalStorage} alt="" />
              <div className="white-bg" />
            </Container>
          </UserProgressContainer>
        )}
    </>
  );
};

UserProgress.defaultProps = {
  image: '',
  percentLabel: '',
  sizeDefault: '',
  overflowImage: false,
  mini: false,
  mobile: false,
  topbar: false,
  autoHeight: false,
  zeroMarginTop: false,
};

UserProgress.propTypes = {
  percent: PropTypes.number.isRequired,
  percentLabel: PropTypes.any,
  sizeDefault: PropTypes.any,
  mini: PropTypes.bool,
  mobile: PropTypes.bool,
  topbar: PropTypes.bool,
  overflowImage: PropTypes.bool,
  image: PropTypes.string,
  autoHeight: PropTypes.bool,
  zeroMarginTop: PropTypes.bool,
};

export default UserProgress;
