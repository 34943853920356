import React from 'react';

import ReceivedMessageCell from './ReceivedMessageCell/index';
import SentMessageCell from './SentMessageCell/index';

const Conversation = ({ userId, conversationData, selectedUser=[] }) => {
  return (
    <div className="gx-chat-main-content">
      {conversationData.map((conversation, index) =>
        conversation.from == userId ? (
          <SentMessageCell key={index} conversation={conversation} />
        ) : (
          <ReceivedMessageCell
            key={index}
            conversation={conversation}
            user={selectedUser}
          />
        )
      )}
    </div>
  );
};

export default Conversation;
