import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Icon, Input, message, notification } from 'antd';
import { connect } from 'react-redux';

import {
  userSignUp,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userTwitterSignIn,
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress';
import logo from '../assets/images/logo_horizontal.png';

const FormItem = Form.Item;

const SignUp = ({ form, showAuthLoader, userSignUp, newlyCreatedUserEmail, authUser, showMessage, loader, alertMessage, hideMessage, history }) => {
  const [agree, setAgree] = useState(true);

  const openNotification = () => {
    const alertMessageStr = String(alertMessage);

    const args = {
      message: 'SignUp',
      description: alertMessageStr.charAt(0).toUpperCase() + alertMessageStr.slice(1),
      duration: 5,
    };
    notification.open(args);
  };

  useEffect(() => {
    if (showMessage) {
      openNotification();
      hideMessage();
    }
    // eslint-disable-next-line
  }, [showMessage, hideMessage]);

  useEffect(() => {
    if (authUser !== null) {
      history.push('/');
    }
  }, [authUser, history])

  useEffect(() => {
    if (newlyCreatedUserEmail) {
      history.push('/confirm');
    }
  }, [newlyCreatedUserEmail, history])

  const handleSubmit = e => {
    e.preventDefault();
    const { validateFields } = form;

    if (!agree) {
      message.error('You must accept the terms and conditions to continue.');
    }
    else {
      validateFields(async (err, values) => {
        if (!err) {
          showAuthLoader();
          userSignUp(values);
        }
      });
    }
  };

  const onChangeCheckbox = e => {
    setAgree(e.target.checked);
  }

  const { getFieldDecorator } = form;

  return (
    <div className="gx-login-container">
      <div
        className="gx-login-content"
        style={{ padding: '10px 35px 20px', position: 'absolute' }}
      >
        <div
          className="gx-login-header gx-text-center"
          style={{ marginBottom: 0 }}
        >
          <img src={logo} alt="" style={{ padding: '30px 0' }} />
        </div>
        <Form
          onSubmit={handleSubmit}
          className="gx-login-form gx-form-row0"
        >
          <FormItem>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Name is required.',
                },
              ],
            })(
              <Input
                style={{ height: '50px' }}
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                size="large"
                placeholder="Name"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: 'This is not a valid email.',
                },
              ],
            })(
              <Input
                style={{ height: '50px' }}
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                size="large"
                placeholder="Email"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'This is not a valid password.' },
              ],
            })(
              <Input
                style={{ height: '50px' }}
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                size="large"
                placeholder="Password"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('confirmPassword', {
              rules: [
                { required: true, message: 'Retype your password.' },
              ],
            })(
              <Input
                style={{ height: '50px' }}
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                size="large"
                placeholder="Password confirmation"
              />
            )}
          </FormItem>

          <FormItem>
            {getFieldDecorator('agree', {
              valuePropName: 'checked',
              initialValue: true,
            })(
              <Checkbox onChange={onChangeCheckbox}>
                <IntlMessages id="appModule.iAccept" />
              </Checkbox>
            )}
            <span className="gx-signup-form-forgot gx-link">
              <IntlMessages id="appModule.termAndCondition" />
            </span>
          </FormItem>

          <FormItem className="gx-text-center">
            <Button type="primary" htmlType="submit" block size="large">
              Sign Up
            </Button>
          </FormItem>
          <div className="gx-flex-row gx-justify-content-between">
            <span>or connect with</span>
            <ul className="gx-social-link">
              <li>
                <Icon
                  type="google"
                  onClick={() => {
                    showAuthLoader();
                    userGoogleSignIn();
                  }}
                />
              </li>
              <li>
                <Icon
                  type="facebook"
                  onClick={() => {
                    showAuthLoader();
                    userFacebookSignIn();
                  }}
                />
              </li>
              <li>
                <Icon
                  type="github"
                  onClick={() => {
                    showAuthLoader();
                    userGithubSignIn();
                  }}
                />
              </li>
              <li>
                <Icon
                  type="twitter"
                  onClick={() => {
                    showAuthLoader();
                    userTwitterSignIn();
                  }}
                />
              </li>
            </ul>
          </div>
        </Form>
      </div>
      {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : null}
    </div>
  );
}

SignUp.defaultProps = {
  authUser: null,
  newlyCreatedUserEmail: null,
}

SignUp.propTypes = {
  authUser: PropTypes.any,
  form: PropTypes.any.isRequired,
  loader: PropTypes.bool.isRequired,
  showAuthLoader: PropTypes.func.isRequired,
  newlyCreatedUserEmail: PropTypes.any,
  userSignUp: PropTypes.func.isRequired,
  alertMessage: PropTypes.any.isRequired,
  showMessage: PropTypes.any.isRequired,
  hideMessage: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
};

const WrappedNormalLoginForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser, newlyCreatedUserEmail } = auth;
  return { loader, alertMessage, showMessage, authUser, newlyCreatedUserEmail };
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
})(WrappedNormalLoginForm);
